:root {
  --primary-color: #1c9093;
  --secondary-color: #2ecc71;
  --title-color: #313839;
  --font-size: 16px;
  --font-family: "Montserrat", sans-serif;
  --padding: 10px;
  --padding-x: 35px;
  --main-heading: 18px;
  --heading: 16px;
  --sub-heading: 14px;
  --sub-title: 12px;
  --banner-main-sm-heading: 36px;
  --banner-main-sm-sub-heading: 28px;
  --banner-sm-text: 14px;
  --banner-sm-input: 14px;
  --banner-sm-sub-text: 12px;
  --banner-main-xs-heading: 28px;
  --card-sm-title: 20px;
  --card-sm-sub-title: 12px;
  --card-sm-price: 19px;
  --card-xs-title: 18px;
  --card-xs-sub-title: 10px;
  --card-xs-price: 17px;
  --card-xs-svg: 15px;
  --banner-xs-option: 11px;
  --banner-xs-sub-option: 12px;
  --banner-xs-sub-option-1: 10px;
  --body-xs-heading: 28px;
  --card-xs-heading: 20px;
  --card-xs-sub-heading: 12px;
  --card-xs-price: 13px;
  --body-sm-main-heading: 38px;
  --body-sm-main-heading-2: 26px;
  --body-sm-sub-heading: 22px;
  --body-sm-title: 20px;
  --body-sm-small-tag: 12px;
  --body-sm-para: 18px;
  --body-xs-main-heading: 30px;
  --body-xs-heading: 22px;
  --body-xs-sub-heading: 19px;
  --body-xs-title: 17px;
  --body-xs-main-heading-2: 22px;
  --body-xs-small-tag: 10px;
  --body-xs-para: 16px;
  --body-xs-sub-para: 12px;
  --body-xs-btn: 12px;
}


.new_heading {
  font-size: var(--heading) !important;
  font-weight: 600;
  color: #646C6D;
}

.move_icon_ani {
  transition: all .35s linear;
}

.move_icon {
  transform: rotate(180deg);
}


.new_sub_heading {
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #646C6D;
  font-size: var(--sub-heading);
}








.top_bar {
  padding: 0 var(--padding-x);
}

.button_loading {
  width: 20px;
  height: 20px;
}

.nav_btn button {
  padding: .6rem 1rem;
  background: none;
  border: 1.5px solid var(--primary-color);
  font-family: var(--font-family);
  color: var(--primary-color);
  border-radius: 5px;
  font-size: var(--font-size);
  font-weight: 400;
  line-height: 18.4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.enquiry h5 {
  color: #646A6A;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.enquiry h5 span {
  color: var(--primary, #1C9093);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.nav_ul {
  background: #f8f8f8;
  padding-right: 1.8rem;
}
.nav_ul ul li {
  list-style: none;
  color: #646C6D;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;

}

.custom-arrow {
  background: white !important;
  box-shadow: 0 0 10px lightgray;
  color: var(--primary-color) !important;
  width: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  height: 25px !important;

}

.slick-prev:before {
  content: '' !important;
  background: none !important;
}

.slick-next:before {
  content: '' !important;
}

.nav_ul ul li hr {
  margin-top: 5px;
}

.menue {
  list-style: none;
  text-transform: uppercase;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #646C6D;
  cursor: pointer;
}

.nav_ul select {
  background: none;
  color: #646C6D;
  border: none;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  outline: none;
  cursor: pointer;

}

.main_banner_home {
  padding: 0 var(--padding-x);

}

.video-background {
  position: relative;
  margin: 20px auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 1rem;
  overflow: hidden;

}

.video-background .see_property_btn {
  /* position: absolute;
  left: 10px;
  top: 10px; */
  background: white;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: .5rem 1rem;
  border-radius: 5px;
  z-index: 99;
  transition: all .35s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.video-background:hover .see_property_btn {
  opacity: 1;

}

.see_property_btn_div {
  position: absolute;
  top: 10px;
  left: 10px;

}

.banner_section {
  position: relative;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
}

#bgVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.agents .card-1 .row .col-9 .link {
  text-decoration: none;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
}

#bgImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* z-index: -1; */
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.banner_section .content{

  z-index: 1;
  color: white;
  height: max-content;
  background: #2c353564;
  text-align: center;
  padding: 1rem;
  /* margin-top: 64px; */
  /* margin-top: 20%;s */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 10px 10px 10px 10px;
}
.video-background .content {

  z-index: 1;
  color: white;
  height: max-content;
  background: #2c353564;
  text-align: center;
  padding: 3rem;
  margin-top: 64px;
  /* margin-top: 20%;s */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  border-radius: 10px 10px 10px 10px;
}

.banner_section .content p,
.video-background .content p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27.12px;
  text-align: center;


}

.banner_section .content h1,
.video-background .content h1 {
  font-family: var(--font-family);
  font-size: 44px;
  font-weight: 600;
  line-height: 58.51px;
  text-align: center;



}

.tab_banner {

  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.tab_section {
  background: white;

  padding: .5rem 1rem;
  border-radius: 0 5px 5px 5px;

}

.tab_banner .tb_btn {
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px 5px 0 0;
  margin-right: .5rem;
  font-weight: 400;
  font-family: var(--font-family);
  background: white;

}

.select_banner {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.select_banner .text-dark {
  font-size: 22px;
}

.select_banner select {
  width: 300px;
  padding: .5rem;
  outline: none;
  border: none;
  background: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24.11px;
  text-align: left;
}

.select_banner input {
  width: 230px;
  padding: .5rem;
  outline: none;
  border: none;
  background: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24.11px;
  text-align: left;
}

.banner_section .select_banner_btn,
.video-background .select_banner_btn {
  background: var(--primary-color);
  margin: 0;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  align-items: center;
  border-radius: 5px;
}

.banner_section .select_banner_btn button,
.video-background .select_banner_btn button {
  border: none;
  background: none;
  font-family: var(--font-family);
  color: white;
  padding: 0 1rem;
}

.mor_option {
  width: 872px;
}

.tab_pan_main {
  width: 872px;

}

.mor_option .more_div {
  padding: 0rem 1rem;
  width: 200px;
  background: white;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #737579;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;

  text-align: left;
}

.mor_option div label {
  font-size: 13px;
  font-weight: 600;
}

.mor_option button {
  padding: 1rem 1rem;
  width: 200px;
  background: white;
  border-radius: 5px;
  border: none;
  display: flex;
  justify-content: space-between;
  color: #737579;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

.tab_pan h6 {
  color: #A8A8A8;

  cursor: pointer;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  margin: 1rem;
}

.active_tab {
  color: var(--primary-color) !important;
  border-bottom: 2px solid;
}

.option_tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.option_tabs p {
  background: #D9D9D9;
  color: #6E7778;
  margin-left: 10px;
  padding: .2rem 1rem;
  border-radius: 5px;
  margin: .5rem;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: center;


}

.btn_popular {

  background: #F9F9f9;
  padding: .3rem 0;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-right: 6px;
  display: flex;
}

.popular_properties h1 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px;

}


.btn_popular button {
  background: white;
  border: none;
  border-radius: 5px;
  padding: .2rem 1rem;
  color: var(--primary-color);
  margin: 0 .5rem;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  letter-spacing: -0.09px;
  font-family: var(--font-family);

}

.inp_populer {
  background: #f0eff7a1;
  border: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0;
  color: var(--primary-color);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
}

.popular_properties .new_inp_populer {
  background-color: white !important;
}

.inp_populer input {
  width: 300px;
  height: 100%;
  border: none;
  background: none;
  outline: none;

}

.property-item {
  box-shadow: 0 0 3px rgba(80, 80, 80, 0.348);

  border-radius: 5px;
  font-family: var(--font-family) !important;
}


.property-item img {
  transition: .5s;
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}

.property-item a {
  width: 100%;
}

.property-item:hover img {
  transform: scale(1.1);
}

.property-item .p-4 h5 span {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.property-item .p-4 h5 {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(1vw + 1rem);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: var(--primary-color) !important;

}

.property-item .p-4 p {

  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;

  font-size: calc(16px);
  font-family: var(--font-family);


}

.property-item .p-4 h5 .price_unit {

  font-family: var(--font-family);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  color: #0009295a !important;

}

.property-item .p-4 .h5 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #313839 !important;
}

.property-item .position-relative .position-absolute {
  background: var(--primary-color);
  left: -10px;
  bottom: -15px;
  color: white;
  border-radius: 5px;
  padding: .5rem;

  font-size: 12px;
  font-weight: 700;

  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-item .position-relative .position-absolute img {
  height: 15px;
  margin-right: 5px;
  margin-top: 2px;
}

.property-item .position-relative .svg {
  left: -9px;
  bottom: -22px;
  position: absolute
}

.tab_pan_project .search_bar_main {
  width: 950px;
  background: white;
  padding: .5rem 1rem;
  color: #737579 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab_pan_project .search_bar_main .inp_serch label {
  font-size: 13px;
  font-weight: 600;
  font-family: var(--font-family);
  color: #737579;
}
.tab_pan_project .search_bar_main .inp_serch {
  width: 100px;
  text-align: start;
  padding-right: .5rem;
  margin-right: .5rem;
}

.tab_pan_project .search_bar_main .inp_serch input {
  border: none;
  outline: none;
  color: #737579;
  font-family: var(--font-family);
  font-size: 13px;
}

.tab_pan_project .search_bar_main .inp_serch ul {
  position: absolute;
  width: 130px;
  background: white;
  list-style: none;
  padding: 0;
  top: 105%;
  border: 1px solid lightgray;
  left: -15px;

}

.tab_pan_project .search_bar_main .inp_serch ul li {
  padding: .3rem;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}

.tab_pan_project .search_bar_main .inp_serch ul li:hover {
  background: #1877F2;
  color: white;
}
.tab_pan_project .search_bar_main .btn_box {
  justify-self: end;
  padding: .5rem 1rem;
  background: var(--primary-color);
  font-family: var(--font-family);
  border-radius: 5px;
  display: flex;
  color: white !important;

}

.tab_pan_project .search_bar_main .market {
  font-family: var(--font-family);
  border-radius: 5px;
  display: flex;
  background: none;
  color: #737579;
  padding: 0px;
  border: none;
  color: var(--heading, #646C6D);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

}

.tab_pan_project .search_bar_main .btn_box button {
  background: none;
  color: white;
  border: none;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.range_dropDown .col-6 {
  max-height: 200px;
  overflow-y: auto;
  padding: .2rem;
}


.range_dropDown .col-6::-webkit-scrollbar {
  width: 8px;

}

.range_dropDown .col-6::-webkit-scrollbar-track {
  background: #f1f1f1;

  border-radius: 10px;
}

.range_dropDown .col-6::-webkit-scrollbar-thumb {
  background-color: #888;

  border-radius: 10px;

  border: 2px solid #f1f1f1;

}

.range_dropDown .col-6::-webkit-scrollbar-thumb:hover {
  background: #555;

}

.our_project .col-lg-4 h4 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;

}

.our_project .col-lg-4 h6 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -1px;
  text-align: left;
  color: var(--primary-color);

}

.our_project .col-lg-3 p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  display: flex;

  align-items: center;


}

.our_project .col-lg-3 .card_footer span {
  font-family: var(--font-family);

  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;


}

.neighborhood_properties .col-lg-4 .main_card {
  width: 100%;
  display: flex;
  background: #F1FFFF;
  padding: 1rem;
  border-radius: 5px;
}

.neighborhood_properties .col-lg-4 .main_card .img {
  width: 138px;
  height: 170px;
}

.neighborhood_properties .col-lg-4 .main_card .img img {

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);

}

.neighborhood_properties .col-lg-4 .main_card .content_neignor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
}

.neighborhood_properties .col-lg-4 .main_card .content_neignor h1 {
  font-family: var(--font-family);
  font-size: 57.3px;
  font-weight: 600;
  line-height: 65px;
  text-align: left;
  color: var(--primary-color);

}

.neighborhood_properties .col-lg-4 .main_card .content_neignor h5 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 29.26px;
  text-align: left;
  color: #5A5564;


}

.neighborhood_properties .col-lg-4 .main_card .content_neignor .link {
  color: #1877F2;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;


}

.client_project {
  padding: 2rem 1rem;
  background: #F1FFFF;
}

.client_project .row .col-md-6 h1 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--primary-color);

}

.client_project .row .col-md-6 h1 span {
  color: #025A5C;
}

.client_project .row .col-md-6 p {
  font-family: var(--font-family);

  font-weight: 500;
  line-height: 25.6px;
  text-align: left;
  color: #646C6D;

}

.card_client {
  background: #56D1D4;
  width: 536px;
  display: flex;
  padding: 2rem;
  border-radius: 5px;
  margin-right: 1rem;
}

.card_client .icon_box {
  margin-right: 1rem;
}

.card_client .icon_box .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  background: #E8E6F9;
  color: var(--primary-color);
}

.card_client .conainter_client h4 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--primary-color);
}

.card_client .conainter_client p {
  font-family: var(--font-family);
  font-weight: 600;
  line-height: 25.6px;
  text-align: left;
  color: var(--primary-color);
}

.main_card_client {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}

.counter .col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--primary-color);
}

.counter .col-md-3 p {
  font-family: var(--font-family);
  color: var(--primary-color);
  font-weight: 500;
  line-height: 25.6px;
  text-align: center;

}

.counter .col-md-3 h1 {
  font-family: var(--font-family);
  color: var(--primary-color);
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.01em;
  text-align: center;


}

.agent {
  width: 100%;
  padding: 1rem;
  display: flex;
  background: #1C9093;
  border-radius: 5px;
}

.agent .agen_content {

  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  color: white;
  height: 100%;
}

.agent .agen_content::-webkit-scrollbar {
  display: none;
}

.agent .agen_content h1 {
  color: var(--White, #FFF);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  line-height: 30px;

}

.agent .agen_content p {
  color: var(--White, #FFF);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  margin: 0;



}

.agent_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;


}

.agent_btn button {
  padding: 19px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid var(--primary, #1C9093);
  background: var(--White, #FFF);
  color: var(--primary, #1C9093);
  text-align: center;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer {
  background: #F7F6F5;
  padding: 1rem 35px;
}

.social_link {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.social_link a {
  margin-left: 1rem;
  text-decoration: none;
  color: #646C6D;
}

.social_link span {
  font-family: var(--font-family);
  font-size: 19px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: #646C6D;
  display: flex;

}

.footer .row .col-12 h6 {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--title-color);
  margin-bottom: 29.54px;
}

.footer .row .col-12 h5 {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: var(--title-color);
  margin-bottom: 29.54px;
}

.footer .row .col-12 ul {
  padding: 0;
}

.footer .row .col-12 ul li {
  color: var(--heading, #646C6D);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.5px;

  list-style: none;
}

.footer .footer_inp {
  display: flex;
}

.footer .footer_inp input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
}

.footer .footer_inp button {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: .3rem 1rem;
  color: var(--primary-color);
  background: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .col-12 ul li,
.footer .col-12 ul li {
  color: #646C6D !important;
  font-family: var(--font-family) !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 28.5px;
  text-align: left;
}

.footer .copy_right {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #646C6D;
  margin-bottom: 0;

}

.menu {
  font-family: var(--font-family) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

}

.login_modal p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: var(--spacing-2);
  text-align: left;

}

.login_modal span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #2E3536;

}

.login_modal span .link {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: var(--primary-color);
  text-decoration: none;


}



.inp_login {
  /* padding:0 1rem ; */
  margin-bottom: 0.6rem;
  position: relative;
}

.inp_login input {
  width: 100%;
  border: 1px solid lightgray;
  padding: .5rem .9rem;
  border-radius: 5px;
  outline: none;
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
  margin-top: 4px;
  cursor: pointer;
}

.inp_login input::placeholder {
  color: #A8A8A8;
  font-size: var(--sub-title);
}

.inp_login select {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

}

.inp_login label {
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.tab_pan_project .search_bar_main .inp_serch ul {
  width: 200px;
  position: absolute;
  border: 1px solid lightgray;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  z-index: 99;
}

.tab_banner .tab_section .select_banner ul,
.inp_login ul {
  width: 100%;
  position: absolute;
  border: 1px solid lightgray;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  z-index: 99;
}

.tab_banner .tab_section .select_banner ul li,
.inp_login ul li {
  list-style: none;
  padding: 0rem 1rem;
  cursor: pointer;
  font-family: var(--font-family);
}

.inp_login .filter_box {
  border: 1px solid lightgray;
  border-radius: 5px;
  background: white;
}

.inp_login .filter_box span {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tab_banner .tab_section .select_banner ul li:hover,
.inp_login ul li:hover {
  background: #2196F3;
  color: white !important;
}

.not_fonnd {
  font-family: var(--font-family);
  color: #626262;
  margin-top: 30px;
}

.text_error {
  position: absolute;
  font-family: var(--font-family);
  font-size: 12px !important;
  font-weight: 600 !important;
  top: 100%;
  left: 0%;
  color: rgb(245, 40, 40) !important;
}


.logo_prop .Sign_inBtn,
.login_modal .Sign_inBtn {
  padding: 7px 20px;
  width: 100%;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  font-family: var(--font-family);
}

.contact_us .Sign_inBtn {
  padding: 7px 20px;
  width: 100%;
  background: var(--primary-color);
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  margin: 1rem 0;
  font-family: var(--font-family);
}


.report_btn {
  padding: 7px 20px;
  width: 100%;
  background: white;
  color: #1C9093;
  border: 1px solid #1C9093;
  border-radius: 4px;
  margin: 1rem 0;
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  align-items: center;
}


.login_modal .text-center {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 400;
  line-height: 15.85px;
  color: #646C6D;

}

.socail_login {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 15.85px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  padding: .5rem;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
  background: none;
}

.socail_login img {
  width: 20px;
  margin-right: 10px;
}

.property_small small {
  color: #000929;
  white-space: nowrap;
}

.listing_main_img {
  margin-bottom: 1.5rem;
}

.listing_main_img img {
  height: 612px;

  object-fit: cover;
  width: 100%;
}

.logo_prop {
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 8px;
  border: 1.5px solid #F0EFFB;



}

.listing_main h1 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing_main h2 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1px;
  text-align: left;
  color: var(--primary-color);
}

.listing_main h2 span {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  letter-spacing: .5px;
  color: #808494;
  margin-left: 8px;
}

.listing_main .discription {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  margin-bottom: 8px;
}

.new_hr_line {
  border: 1.5px solid #F0EFFB;
  margin: 10px 0 16px 0;
}

.mian_feature {
  background: #F1FFFF;
  padding: .7rem 1.3rem;
  border-radius: 5px;
}

.mian_feature h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #646C6D;

}

.listing_main .location_heading {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
  margin-bottom: 0;
}

.mian_feature .row .col-lg-3 span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.new_listing_main {
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 8px;
  border: 1.5px solid #F0EFFB;
  padding-bottom: 20px;
}

.listing_main .map-head {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
  color: #A8A8A8;
  margin-bottom: 7px;
}

.listing_main .new-map-head {
  color: #313839;
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
  margin-bottom: 7px;
}

.listing_main .property_small {
  gap: 16px;
}

.listing_main small {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: #000929;
  gap: 8px;
}

.listing_main .discrip_head {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
  margin-top: 16px;
}

.listing_main .discrip_subhead {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #646C6D;
}

.listing_main .read_more {
  background: none;
  border: none;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.listing_main h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.listing_main h3 span {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #A8A8A8;
}

.house_finance {
  background: #F1FFFF;
  padding: 1rem;
}

.house_finance p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #646C6D;

}

.inp_login select {
  width: 100%;
  border: 1px solid lightgray;
  padding: 0.7rem;
  border-radius: 5px;
  outline: none;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
}

.house_finance .payment_card {
  border: 1px solid lightgray;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  height: 100%;
  border-radius: 5px;
}

.house_finance .payment_card h1 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #646C6D;
}

.house_finance .payment_card h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #646C6D;
}

.house_finance .payment_card .bar_main {
  width: 80%;
  height: 20px;
  border-radius: 5px;
  background: lightgray;
  position: relative;
}

.house_finance .payment_card .bar_main .bar {
  width: 80%;
  height: 20px;
  border-radius: 5px;
  background: var(--primary-color);
  position: absolute;
}

.house_finance .apply_btn {
  padding: .5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
}

.form_radio span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;


}
.form_radio span.MuiButtonBase-root{
  padding: 6px;
}

.form_radio label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #646C6D;

}

.logo_info {

  text-align: center;
}

.logo_img img {

  width: 113px;
  height: 113px !important;
  object-fit: contain;
  border-radius: 50%;

}

.heading_info {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #313839;
  margin: 0;
  padding-top: 8px;
}

.content_info {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #313839;
  margin: 0;
}

.info_btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info_btns button {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #646C6D;
  border: 1px solid #A8A8A8;
  background-color: transparent;
  padding: 5px 15px;
  margin: 10px;
  border-radius: 3px;
}

.logo_label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #646C6D;
  margin-top: 1rem;
}

.logo_form input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #313839;
  margin-top: 5px;
  padding: 8px 14px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px 0px #1018280D;
}

.inp_login .contact_contry {
  display: flex;
}

.inp_login .contact_contry .img_container {
  /* width: 145px; */
  display: flex;
padding: 0 25px;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px 0 0 5px;
  background: #f5f5f5;
  height: 42px;
  margin-top: 4px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}

.inp_login .contact_contry .img_container span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #545468;

}

.treands_main {
  border: 1px solid lightgray;
  background: #F1FFFF;
  border-radius: 5px;
  padding: 1rem;
}

.treand_box {
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 5px;
  background: white;

}

.treand_box h2 {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #313839;

}

.treand_box .d-flex span {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;

  text-align: left;
  color: white;
  background: #A8A8A8;
  border-radius: 5px;
  padding: .2rem .5rem;
  margin: .5rem;
}

.treand_box .d-flex .active {
  background: var(--primary-color);
}

.treand_box table {
  width: 100%;
  margin-top: 10px;
}

.treand_box table tbody td .range_main {
  display: flex;
  align-items: center;
}

.treand_box table tbody td {
  height: 30px;
}

.treand_box table tbody td .range {
  background: var(--primary-color);
  height: 20px;
  border-radius: 5px;
}

.treand_box table tbody td .text_change {
  color: gray;
}

.main_similer h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  width: 250px !important;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  width: 5px;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar-thumb {
  background-color: #1C9093;
  border-radius: 25px;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar-track {
  background: transparent;
}

.css-1hsz34s-MuiDrawer-docked {
  width: 250px !important;
}

.css-1b6x7je-MuiListItem-root {
  width: 82% !important;
}

.inp_bar {
  border: 1px solid lightgray;
  margin-left: 50px;
  border-radius: 5px;
  padding-left: 10px;
  background: #F5F6FA;
}

.inp_bar input {
  padding: .5rem;
  width: 389.292px;
  height: 38px;
  flex-shrink: 0;
  background: none;
  font-family: var(--font-family);
  border: none;
  outline: none;
}

.bar_btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;

}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: var(--font-family) !important;
}


.bar_btn button {
  padding: 5px 20px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  font-family: var(--font-family);
  font-size: var(--spacing-2);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: 2px solid var(--primary-color);
  display: flex;
  align-items: center;

}

.bar_btn .btn1 {
  color: white;
  background: var(--primary-color);
}

.bar_btn .btn2 {
  background: none;
  color: var(--primary-color);
}

.add_propertey_main {
  padding: 1rem 1rem 0 1rem;
}

.content_box {
  /* overflow-y: scroll; */
}

.content_box::-webkit-scrollbar {
  display: none;
}


.add_propertey_main .heading_mian {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

}

.heading_title_main button {
  display: flex;
  padding: 5px 18px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--primary, #1C9093);
  color: var(--White, #FFF);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .3s linear;

}
.clearBTn{
  background-color: #1C9093;
  color: white;
  border: none;
padding: .5rem 1.5rem;
margin-left: 10px;
border-radius: 5px;
transition: all .3s linear;
}

.add_propertey_main .locationPurpos {
  border: 1px solid lightgray;
  border-radius: 5px;
  background: white;
  padding: 1rem;
}

.add_propertey_main .locationPurpos h4 {
  margin: 4px 0 0 .5rem;
  color: var(--heading, #646C6D);
  font-family: var(--font-family);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  margin-left: .5rem;

}

/* .add_propertey_main .locationPurpos h6 {
  font-family: var(--font-family);
  font-size: var(--spacing-2);
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #646C6D;
  font-size: 14px;
} */

.add_propertey_main .locationPurpos button {
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid lightgray;
  color: #A8A8A8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all .3s linear;
}

.add_propertey_main .quantity_btn {
  padding: 0.2rem 0.9rem !important;
}

.scroll-box {
  overflow-x: auto;
}

.scroll-box button {
  margin-bottom: 5px;
}

.scroll-box::-webkit-scrollbar {
  height: 3px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #1C9093;
  border-radius: 25px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}

.locationPurpos .listing_table {
  overflow: scroll;
}

.add_propertey_main .locationPurpos .listing_table::-webkit-scrollbar {
  height: 5px;
}

.add_propertey_main .locationPurpos .listing_table::-webkit-scrollbar-thumb {
  background-color: #1C9093;
}

.active_btn {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;

}

.active_btn_home {
  background: var(--primary-color) !important;
  color: white !important;
}

.feature_amenities .inp_login button {
  padding: .5rem 1rem;
}

.tab_lable {
  font-family: var(--font-family);
  font-size: var(--spacing-2);
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #A8A8A8;
  display: flex;
}

.tab_lable p {
  cursor: pointer;
  padding: 0;
  margin: 0;

}

.active_lable {
  color: var(--primary-color) !important;
}

.inp_login .active_btn {
  background: var(--primary-color) !important;
  color: white !important;
}

/* .feature_amenities .inp_login label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
} */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e8e6e689;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border: 1px solid lightgray;
  left: 0px;
  bottom: -3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  color: #646C6D;
}

.switch input:checked+.slider {
  background-color: var(--primary-color);
}

.switch input:checked+.slider:before {
  transform: translateX(20px);
}

.feature span {
  background: #494949;
  color: white;
  padding: .3rem 1rem;
  font-size: 13px;
  border-radius: 5px;
  font-family: var(--font-family);
  white-space: nowrap;
}

.feature_amenities .feature {
  background: #F1FFFF;
  padding: .5rem;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.inp_login p {
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #A8A8A8;
}

.feature_box p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin: .3rem 0;
  text-align: left;
  color: #494949;
}

.adInfo .row .col-12 textarea {
  width: 100%;
  height: 100px;
  border: 1px solid lightgray;
  border-radius: 5px;
  resize: none;
  padding: 1rem;
  font-size: var(--sub-title);
}

.adInfo .row .col-12 textarea::placeholder {
  font-size: var(--sub-title);
}

/* 
.propertyimage h5 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
} */

.image_section {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
  /* height: 100%; */
}

.image_section .row .col-12 ul li {
  list-style: none;
  color: #646C6D;
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin-top: 10px;
}

.image_section .row .col-12 button {
  border: 1px solid var(--primary-color);
  width: 150px !important;
  display: flex;
  justify-content: center;
  padding: .3rem;
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.image_section .row .col-12 .btn1 {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: white;
  padding: .3rem .2rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 126px !important;
  font-size: var(--sub-heading);
  transition: all .3s linear;
  cursor: pointer;
  transition: all .3s linear;
}

.image_section .row .col-12 .btn2 {
  color: var(--primary-color);
  background: none;
}

.image_section .row .col-12 .image_main {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
}

.image_section .row .col-12 .image_main img {
  width: 100px;
  position: absolute;
  height: 100px;
  object-fit: cover;
}

.propertyimage h3 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  margin-top: 20px;
}

.propertyimage p {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #A8A8A8;
}

.propertyimage .video_link {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 1rem;
}

.propertyimage .video_link .d-flex label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.propertyimage .video_link .d-flex span {
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #FF0000;
  display: flex;
  align-items: center;
}

.propertyimage .video_link input {
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
}

.propertyimage .video_link input::placeholder {
  font-size: var(--sub-title);
}

.video_link .add_btn {
  font-family: var(--font-family);
  font-size: var(--sub-title);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: white !important;
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.contectInformation .inp_login button {
  color: #FF3D00;
  font-size: 25px;
}

.contectInformation .inp_login .mx-2 {
  color: var(--primary-color);
}

.modal_feature .heading {
  color: var(--primary-text, #494949);
  font-family: var(--font-family);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 16px !important;
}

.modal_feature {
  border-radius: 5px;
}

.modal_feature .bank_detail_main {
  max-height: 200px;
  border-radius: 5px;

  overflow-y: auto;
  overflow-x: hidden;
}

.pera_text {
  color: #646C6D !important;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.modal_feature .header_date span {
  color: var(--heading, #646C6D);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.modal_feature .header_date .status {
  width: 74px;
  padding: 3px 11px;
  gap: 10px;
  align-self: stretch;
  border-radius: 30px;

}

.modal_feature .feature_list {
  height: 500px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal_feature .feature_list::-webkit-scrollbar {
  display: none;
}

.modal_feature .model_footer button {
  padding: .3rem 1rem;
  background: none;
  border: none;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

}

.modal_feature .model_footer .add_btn {
  background: var(--primary-color);
  color: white;

}

.modal_feature .feature_list .row .col-3 {
  border-right: 1px solid lightgray;
  height: 100%;
}

.modal_feature .feature_list .row .col-3 ul li {
  list-style: none;
  border-bottom: 1px solid lightgray;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 1rem 0;
  text-align: left;
  color: #646C6D;
  cursor: pointer;
}

.modal_feature .feature_list .row .page .row .col-12 label,
.modal_feature .feature_list .row .page .row .col-6 label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.modal_feature .feature_list .row .page .row .col-12 input,
.modal_feature .feature_list .row .page .row .col-6 input,
.modal_feature .feature_list .row .page .row .col-6 select {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  width: 135px;
  border-radius: 5px;
  padding: .3rem;
  border: 1px solid lightgray;
}

.active_li {
  color: var(--primary-color) !important;
}

.listing_tabs span {
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #A8A8A8;

}

.listing_table table thead th {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 600;
  line-height: 14.63px;
  text-align: left;
  color: #313839;
  background: #F8F9FB;
  padding: 1rem 0.5rem;
  white-space: nowrap;
}

.listing_table table tbody tr th {
  vertical-align: middle;
}

.table-title {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 16% !important;
}

.listing_table table tbody td,
.listing_table table tbody tr th {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #646C6D;
  padding-top: 1rem;
  white-space: nowrap;
  vertical-align: middle;
  width: 9%;
}

.listing_table table tbody td .table_title {
  white-space: wrap !important;
  text-overflow: ellipsis;
}

.listing_table table tbody td .table_action_button button {
  font-family: var(--font-family);
  color: var(--White, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.listing_table table tbody td .table_action_button .action_btn {
  background: var(--primary);
  padding: .2rem 1rem !important;
}

.listing_table table tbody td .plateform {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: var(--primary-color);
  border: 1px solid lightgray;
  padding: .5rem 1rem;
  border-radius: 50px;
  white-space: nowrap;
}

.listing_table table tbody td .status {
  font-family: var(--font-family);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 3px 11px;
  border-radius: 50px;
}

.modal_feature .header_date .status-approved,
.listing_table table tbody td .status-approved {

  background: #E2FFF4;
  color: #519C66;
}

.modal_feature .header_date .status-rejected,
.listing_table table tbody td .status-rejected {

  background: #FFF2EB;
  color: #C54600;
}

.modal_feature .header_date .status-saved,
.listing_table table tbody td .status-saved {
  background: #DFEDFF;
  color: #1877F2;
}

.modal_feature .header_date .status-approval,
.listing_table table tbody td .status-approval {

  background: #FFEFBF;
  color: #C67300;
}

.listing_table table tbody td .icons {
  width: 25px !important;
  height: 25px !important;
  background: #ECECEC;
  border-radius: 50%;
  margin: .3rem;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing_table table tbody td .icons img {
  width: 25px !important;
  height: 25px !important;

}

.agency_staff .content_box .profile_section {
  border-right: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.agency_staff .content_box .profile_section .user_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.agency_staff .content_box .profile_section .profile_img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.agency_staff .content_box .profile_section .user_data h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #646C6D;
}

.agency_staff .content_box .profile_section .user_data p {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
  color: #646C6D;
}

.agency_staff .content_box .main_profile .profile_quota p {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  text-align: left;
  color: #646C6D;
}

.agency_staff .content_box .main_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.agency_staff .content_box .main_profile .profile_quota {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.agency_staff .content_box .main_profile .add_staff_btn {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: .3rem 1rem;
}

.modal_feature .feature_list .available_to {
  background: #F1FFFF;
  padding: 1rem;
}

.modal_feature .feature_list .available_to h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: var(--primary-color);
}

.modal_feature .feature_list .available_to .sections {
  border-right: 1px solid lightgray;
  padding: 0 1rem;
}

.modal_feature .feature_list .available_to .sections p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #313839;
}

.modal_feature .feature_list .available_to .sections span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #646C6D;

}

.feature_list .listing_manage .row .col-3 {
  border: none;
  padding: 0;
}

.feature_list .listing_manage .row .col-3 button {
  width: 80%;
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: .3rem;
  border-radius: 5px;

}

.feature_list .listing_manage .row .col-3 button {
  width: 80%;
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: .3rem;
  border-radius: 5px;
  margin: 0 .5rem;
}

.feature_list .listing_manage .row .col-3 input {
  width: 100%;
  background: none;
  border: 1px solid lightgray;
  padding: .3rem;
  border-radius: 5px;
}

.feature_list .listing_manage h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: var(--primary-color);
}

.feature_list .listing_manage .row .col-3 p {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #313839;
}

.feature_list .listing_manage .row .col-3 span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #313839;
}

.inp_login textarea {
  width: 100%;
  margin-top: 9px;
  min-height: 115px;
  resize: none;
  width: 100%;
  border: 1px solid lightgray;
  padding: .5rem;
  border-radius: 5px;
  outline: none;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
}

.agency_setting .d-flex button {
  padding: 5px 18px;
  background: var(--primary-color);
  color: white;
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: none;
  border-radius: 5px;
  transition: all .3s linear;
}

.user_setting .row ul {
  overflow-x: scroll;
}

.user_setting .row ul::-webkit-scrollbar {
  height: 3px;
  width: 20px !important;
}

.user_setting .row ul::-webkit-scrollbar-thumb {
  background-color: #1C9093;
  border-radius: 25px;
}

.user_setting .row ul li {
  list-style: none;
  font-family: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #A8A8A8;
  cursor: pointer;
  white-space: nowrap;
}

.buy_product .table_row {
  background: #F5F6FA;
  padding: .3rem;
  width: 100%;
  margin: 0 auto;
}

.buy_product .payment_heading {
  font-family: var(--font-family);
  font-size: var(--main-heading);
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 12px;
  color: #646c6d;
  text-align: left;
}

.buy_product .table_row h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #313839;
}

.buy_product .table_data {
  max-height: 400px;
}

.buy_product .new_table_data {
  width: fit-content !important;
}


.buy_product .table_body .data_pro {
  display: flex;
  align-items: center;
}

.table .status-col {
  padding-left: 17px;
}

.buy_product .table_body .data_pro .img {
  width: 50px;
  height: 50px;
  background: #F5F6FA;
  border-radius: 50%;
}

.buy_product .table_body .data_pro .img img {
  width: 50px;
  height: 50px;

}

.buy_product .table_body .data_pro span {
  font-family: var(--font-family);
  color: #A8A8A8;
  font-size: var(--sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.buy_product .table_body h6 {
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  font-weight: 500;
  margin-top: 10px !important;
  line-height: 0px;
  text-align: left;
  color: #646C6D;
  white-space: nowrap;
}

.buy_product .table_body button {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background: var(--primary-color);
  color: white;
  border: none;
}

.buy_product .table_body .btn_carts button {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background: none;
  margin: 0 .3rem;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem;
}

.buy_product .table_body .new_table_body h6 {
  margin-left: 10px;
}

.add_propertey_main .cart_item .cart_list {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
}

.add_propertey_main .cart_item span {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-top: 1rem;
}

.add_propertey_main .cart_item p {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.add_propertey_main .cart_item .total span {
  color: var(--primary, #1C9093);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  align-self: stretch;
}

.add_propertey_main .cart_item .total h6 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  align-self: stretch;

}

.add_propertey_main .cart_item button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--White, #FFF);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: var(--primary, #1C9093);
}



.buy_product .content_box .heading_mian span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

}

.order_summery .heading {
  color: var(--title, #313839) !important;
  font-family: var(--font-family) !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  margin: .5rem 0 !important;
  line-height: 32px !important;
}

.order_summery .no_cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}

.order_summery .no_cart .icon {
  font-size: 100px;
  color: #D6EFF0;
}

.order_summery .no_cart span {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #313839;

}

.propertySide .MuiTypography-root {
  font-family: var(--font-family) !important;
  font-size: 14px !important;
  /* font-weight: 600 !important; */
  line-height: 17.07px !important;
  letter-spacing: 0.30000001192092896px !important;
  text-align: left !important;

}

.propertySide .MuiTypography-root {
  font-weight: 500 !important;
}

.contact_us .inp_section {
  background: var(--primary-color);
  color: white;
}

.contact_us .inp_login label {
  color: white;
}

.contact_us .inp_section h2 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;

}

.contact_us .inp_section p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;


}

.contact_us .contact_map h4 {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 500;
  line-height: 22.8px;
  text-align: left;
  color: #313839;

}

.contact_us .contact_map ul li {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 28.5px;
  text-align: left;
  color: #646C6D;
  list-style: none;
}

.contact_us .contact_map ul li .icons {
  margin-right: .5rem;
}

/* ========================lease================= */

.list {
  display: flex;
  justify-content: space-between;
}

.list h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.least span {
  font-size: 1.4rem;
}

.least button {
  background: none;
  border: none;
  border-right: 1px solid lightgray;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #494949;
}

.least-card {
  width: 100%;
  padding: 1rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25)
}

.least-card .col-3 {
  height: 318px;
  padding-right: 0;
}

.least-card .col-3 img {
  height: 100%;
  width: 100%;
  border-radius: 10px 0 0 10px;
}

.least-card .row .col-lg-8 {
  border-radius: 0 5px 5px 0;

}

.last-heading {
  display: flex;
  justify-content: space-between;

}

.latest-mian-div {
  border: 1px solid lightgrey;
  padding-left: 1rem;
  padding: 15px;
  border-radius: 0 8px 8px 0;
}

.last-heading h2 {
  color: var(--primary, #1C9093);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
}

.last-heading h4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #0009299d;
  font-family: var(--font-family);
}

.b-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.b-heading h2 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  font-family: var(--font-family);
}

.market {
  padding: 8px 12px;
  border: 1px solid;
  margin: 0;
  border-radius: 3px;
  background-color: var(--primary-color);
  color: white;
  font-size: 11px;
  font-family: var(--font-family);
}

.least-card .least-logo .logo {
  height: 24px;
  width: 66px;

}

.least-logo .logo img {
  width: 100%;
  height: 100%;
}

.least-logo {
  border-radius: 3px;
  border: 1px solid #1C9093;
  padding: 0;

}

.least-card h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0009299d;
}

.least-img {
  width: 330px;

  display: flex;
  justify-content: space-between;
}

.least-img span {
  display: flex;
  align-items: center;
}

.least-img span img {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.least-img span h4 {
  font-size: 14px;
  color: #0009299d;
  margin-top: 8px;
}

.least-card .col-9 p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #646C6D;
  font-family: var(--font-family);
}

.least-btn button {
  border: 1px solid #A8A8A8;
  border-radius: 3px;
  background: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: #646C6D;
  margin-right: 10px;

}

.least-card .col-9 {
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-radius: 0 5px 5px 0;

}

/* ========================lease================= */

.latest-card .row .col-lg-6 {
  margin-top: 20px;
  padding: 1rem;
}

.latest-img {
  border-radius: 5px;
}

.latest-img .big-img {
  width: 100%;
  height: 322px;

}

.big-img img {
  width: 100%;
  height: 100%;
}

.down-card {
  margin-left: 3px;
  border-radius: 0px 0px 10px 10px;
  margin-top: -5px;
  gap: 0px;
  padding: 1rem;
  opacity: 0px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.link_section {
  text-decoration: none;
}

.down-card h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0009299d;
}

/* */
.down-card p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}

.rect-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* ========================lease================= */

.property-type {
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 8px;
  display: flex;
justify-content: space-between;
}

.property-type .p {
  width: 150px;
  margin-left: 10px;
  border-right: 1px solid var(--primary-color);

}

.property-type .p h4 {
  font-size: 14px;
  font-weight: 400;
}

.property-type .p h3 {

  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--primary-color);
}

/* ========================lease================= */

.similar {
  margin-top: 30px;

}

.similar .row .col-lg-4 {
  border: 1px solid transparent;
  background-color: #FAFAFA;
}

.similar h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  color: rgba(49, 56, 57, 1);
  font-family: var(--font-family);
}

.similar p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(49, 56, 57, 1);
  font-family: var(--font-family);
}

/* ========================lease================= */
.category {
  margin-top: 50px;
}

.category h3 {
  font-size: 39px;
  color: #313839;
  margin-top: 20px;
}

.category .col-lg-4 {
  padding: 9px;
}

.c-box {

  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: white;
}

.category-card {
  display: flex;
  align-items: center;
  background: #F1FFFF;
  padding: 1rem;
}

.category-heading h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: rgba(24, 119, 242, 1);
  font-family: var(--font-family);
}

.category-heading h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.26px;
  color: #646C6D;
}

.category-heading h1 {
  font-size: 57.3px;
  font-weight: 600;
  line-height: 65px;
  color: #1C9093;
}


/* ========================lease================= */



.top-project h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
}

.top-project .project-card {
  height: 460px;
  border-radius: 10px 0px 0px 0px;
  position: relative;

}

.project-card img {
  width: 100%;
  height: 100%;
}

.project-card .main_img {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
}

.project-logo {
  width: 100%;
  position: absolute;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.markit-project {
  display: flex;

}

.p-markit {
  width: 70px;
  height: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  background-color: #1C9093;
  color: white;
  font-size: 11px;
}

.project-icon {
  width: 60px;
  height: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}

.project-icon img {
  width: 45px;
  height: 10px;
}

.project-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 5px;
}

.project-img .pro-icon {
  border-bottom: 2px solid rgba(100, 94, 94, 0.651);
  padding: 3px;
  margin-bottom: 2px;
}

.project-img .pro-icon img {
  width: 15px;
  height: 14.3px;
  filter: grayscale(100%)
}


.fold-card {
  background: var(--primary-color);
  margin-top: 20px;
  bottom: 10px;
  color: white;
  border-radius: 5px;
  padding: .2rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  align-items: baseline;
}

.fold-card h3 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  left: 5px;
}

.project-heading {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1rem;
}

.project-heading .tik {
  height: 23px;
  width: 80px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: white;

}

.tik span {
  font-size: 16px;
  color: #1C9093;
}

.tik h4 {
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #1C9093;
  margin-top: 8px;
  margin-left: 4px;
}

.project-heading h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
  font-family: var(--font-family);
}

.project-heading span {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: white;

}

.project-heading div p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -1px;
  text-align: left;
  color: white;
}

.project-card .over_lay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.678));
  border-radius: 5px;
}

/* ========================lease================= */

.value-box {
  display: flex;
  padding: 10px 10px 20px;
}

.value-box .v-card {
  width: 299px;
  height: 200px;
  gap: 0px;
  box-shadow: 0 0 5px rgba(211, 211, 211, 0.5);
  margin-right: 30px;
  border-radius: 5px;
  padding: 7px;
}

.v-card h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  color: #1C9093;
}

.instant-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.instant-card .v-img {
  width: 55px;
  height: 55px;
  top: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #F1FFFF;
}

.v-card p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  margin-left: 8px;
  color: #313839;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}

.intant-value h2 {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 600;
  line-height: 58.51px;
  text-align: left;
  color: #646C6D;
}

.intant-value p {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: #646C6D;
}

.intant-value h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #313839;
}

.intant-value button {
  width: Fixed (228px)px;
  height: Hug (38px)px;
  top: 337px;
  left: 666px;
  padding: 7px 20px 7px 20px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
  background-color: #1C9093;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
}

.project-card .position-relative .position-absolute {
  background: var(--primary-color);
  left: -10px;
  bottom: 10px;
  color: white;
  border-radius: 5px;
  padding: .5rem;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: left;
  display: flex;
}

.project-card .position-relative svg {
  margin-left: -8px;
  margin-top: 6px;
}

.add_propertey_main .submit_btn {
  padding: .4rem 1rem;
  border: none;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: var(--sub-heading);
  transition: all .3s linear;
}
.add_propertey_main .submit_btn:hover ,.add_propertey_main .locationPurpos button:hover, .heading_title_main button:hover,.clearBTn:hover,.agency_setting .d-flex button:hover, .image_section .row .col-12 .btn1:hover,.filter_pro_btn:hover {
 
  background:#146165 ;
  color: white !important;
  
}
.Deletlist:hover{
background-color: red !important;
border-color: white !important;
}
.Deletlist:hover svg path{

  stroke: white;
}

.menu_bar_btn {
  border: none;
  background: none;
  padding: 0 1rem;
  display: none;
}

.side_menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #dcdbdb;
  left: 0;
  z-index: 999;
  top: -100vh;
  display: flex;
  justify-content: center;
  transition: all .35s linear;
  overflow: auto;
  z-index: 11111111;

}

.down {
  top: 0% !important;
}

.side_menu ul li {
  list-style: none;
  margin-top: 20px;
  font-size: 30px;
  color: var(--primary-color);
  text-align: center;
  font-family: var(--font-family);
  text-transform: uppercase;

}

.side_menu ul li .link_section {
  list-style: none;
  margin-top: 20px;
  font-size: 30px;
  color: var(--primary-color) !important;
  text-align: center;
}

.side_menu ul li button {
  list-style: none;
  margin-top: 20px;
  font-size: 30px;
  background: var(--primary-color) !important;
  text-align: center;
  color: white;
  padding: .2rem 2rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;


}

.unit_ul ul {
  list-style: none;
  display: flex;
  font-family: var(--font-family);
}

.unit_ul ul li {

  border-right: 1px solid;
  padding: 0 1rem;
  cursor: pointer;
}

.unit_ul ul li p {
  font-family: var(--font-family) !important;
}

.menu_item ul li {
  padding: 0 !important;
}

.price_filter {
  width: 200px;
}

.filter_title {
  border-bottom: 1px solid lightgray;
  padding: .3rem;
}

.price_filter .filter_title button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: var(--heading, #646C6D);
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.price_filter .filter_title p {
  color: var(--title, #313839);
  text-align: center;
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.price_filter .row .col-6 {
  text-align: center;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.price_filter .row .col-6 input,
.price_filter .row .col-12 h5,
.price_filter .row .col-6 h5 {
  text-align: center;
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  line-height: normal;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  padding: .3rem 1rem;

}

.qouta_box_mian {
  display: flex;

  justify-content: flex-start;

}

.credits_box {
  flex-grow: 1;

  min-width: 156px;

  max-width: 100%;

  padding: 0.2rem 1.1rem 1rem 0.8rem;
  border-radius: 10px;
  border: 1px solid #D8D8D8;
  background: var(--white, #FFF);
  height: max-content;
  box-sizing: border-box;

  overflow: hidden;

  white-space: nowrap;

  margin-bottom: 5px;
}

.credits_box h6 {
  color: var(--primary, #1C9093);
  font-family: var(--font-family);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;
}

.credits_box div p {
  color: var(--heading, #646C6D);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

}

.main_sidebarRight {
  width: 75%;
  min-height: 100vh !important;
}




.qouta_main .inp_date {
  display: flex;

  align-items: center;
  align-self: stretch;
  padding: .2rem 1rem;
  background: white;
  width: max-content;
  border-radius: 5px;
  border: 1px solid #D8D8D8;
}

.qouta_main .inp_date input {
  border: none;
  outline: none;
  color: var(--faded, #A8A8A8);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

}

.qouta_table_main {
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #D8D8D8;
  padding: 1rem;
  background: var(--white, #FFF);
}

.account_detail {
  background: #F5F6FA;
  padding: 1rem;
  margin-top: 10px;
  width: 563px;
}

.account_detail .title .bank_img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  overflow: hidden;
}

.account_detail_main .heading_mian {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

}

.account_detail .title .bank_img img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  overflow: hidden;
}

.account_detail .title h6 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

}

.account_detail .detail_box p {
  color: var(--primary, #1C9093);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  align-self: stretch;
}

.account_detail .detail_box h6 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

}

.account_detail_main .order_summery h6 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

}

.payment_modal h6 {
  color: var(--title, #313839);
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

}

.payment_modal p {
  color: var(--heading, #646C6D);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

}

.inp_login .attach_lable {
  padding: .3rem 1rem;
  background: var(--primary-color);
  border-radius: 5px;
  color: white;
}

/* ========================about-us================= */

.about-us .img-box {
  height: 320px;
  background-image: url('./Images/About/Rectangle\ 1857.png'), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, #242929 100%);
  background-position: center;
  background-size: cover;
}

.img-box .label {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: center;
  color: #FFFFFF;
}

.img-box .details {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: center;
  color: #FFFFFF;

}

/* ========================what-we-do================= */

.we-container {
  background-color: #F9F9F9;
}

.main-heading {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 39.01px;
  text-align: center;
}

.about-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.about-img img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.img-col {
  margin-left: 75px;
  margin-right: 18px;
}



.textbox-1 .textbox1-heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  color: #313839;

}

.textbox-1 .textbox1-paragraph1,
.textbox-1 .textbox1-paragraph2 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.textbox-2 .texbox2-heading1 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  color: #313839;
}

.textbox-2 .textbox2-heading2 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: left;
  color: #313839;

}

.ul-tag li {
  color: #646C6D;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

}

.ul-tag {
  padding-left: 0 !important;
}

.same-padding {
  padding-left: 0.38rem !important;
}

.same-space {
  padding-left: 1rem !important;
}

.textbox3-heading {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: left;
  color: #313839;

}

.texbox4-heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;

}

.textbox4-paragraph {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;

}

.texbox5-heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;

  color: #313839;

}

.textbox5-paragraph {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}



@media (max-width: 548px) {
  .about-img-row {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .img-col {
    margin-left: 0;
    margin-right: 0;
  }
}


/* ========================Entrance================= */

.entrance-container {
  margin-bottom: 4rem;
}

.first-item {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 4rem;
  color: #646C6D;
  white-space: nowrap;

}

.faqs-heading h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: start;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;

}

.second-item {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #1C9093;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.item-line {
  width: 36px;
  height: 4px;
  border: 2px solid #1C9093;
  margin-right: 9px;
  margin-left: 10px;
}
.site-map .col-12 ul li{
  list-style: none;
}
.site-map .col-12 ul li .link{
text-decoration: none;
color: #494949;
}
.details-col {
  padding-left: 54px;
  padding-right: 0;
}

.main-parabox .para {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #646C6D;

}

.colored-box {
  height: 630px;
  border-radius: 5px;
  background-color: #D9D9D9;
}

.faq-input {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 0 0 10px rgb(243, 238, 238);
  border-radius: 8px;
  background-color: white;

}

.faq-mian-box {
  padding: 1rem;


}

.faq-input input {
  border: none;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #A2A9B0;
  outline: none;
  width: 100%;

}

.faq-input span {
  color: #333333;
  font-size: 20px;
}

.fa-mian-services {
  margin-top: 28px;
}

.faq-services {
  padding: 1rem;
  box-shadow: 0 0 10px rgb(243, 238, 238);
  border-radius: 8px;
  background-color: white;
}

.faq-services span {
  cursor: pointer;
}

.faq-services h3 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.faq-services p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.faq-website {
  box-shadow: 0 0 10px rgb(243, 238, 238);
  border-radius: 8px;
  padding: 1rem;
  background-color: white;
}

.faq-website span h3 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.faq-website span span {
  color: #1C9093;
}

.mian-faqa {
  background-color: #F9F9F9;
}

.faq-services.active h3 {
  color: #1C9093;
}

.faq-services p {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}



/* ========================Agents Banner================= */


.banner_section .new-content {
  min-height: 0px !important;
  border-radius: 5px;
  background: #2C3535BF;
  min-width: 0 !important
}
.banner_section.img_banner{
  background: #000000c7;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, #242929 100%), url(./Images/HomePage/Rectangle\ 732.png); */
}
.banner_section .new-content h1 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;
  text-align: center;
  color: #FFFFFF;
}

.banner_section .new-content p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27.12px;
  text-align: center;
  color: #FFFFFF;
}

.banner_section .new-tab-section {
  height: 57px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.agent_select_banner input{
  width: 100px !important;
}
.banner_section .new-tab-section .select_banner {
  margin-right: 15px;
}

.banner_section .new-tab-section .select_banner select {
  width: auto;
  height: 37px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #737579;
  padding: 0 15px;
}

.banner_section .new-tab-section .select_banner input[type="search"] {
  width: 206px;
  height: 37px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #737579;
  padding: 0 15px;
  margin-right: 15px;
}

.banner_section .new-tab-section .select_banner_btn {
  margin-left: 20px;
}




/* ========================Agents  [ second section]================= */


.titanium {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.main-agentcard-box {
  flex-wrap: nowrap;
  overflow: hidden;
}

.main-agentcard-box .agents-card {
  margin-right: 20px;
}

.agents-card {
  width: 358px;
  border-radius: 5px;
  padding: 0 !important;

}

.agents-box-row {
  flex-wrap: nowrap;
  overflow: hidden;
}

.agents-card .agents-img {
  width: Fixed (124px)px;
  height: Fixed (122px)px;
  padding: 46px 13px 42px 13px;
  border-right: 1px solid #DEE2E6;
}

.agents-card .agents-img1 {
  width: Fixed (124px)px;
  height: Fixed (122px)px;
  padding: 36px 13px;
  border-right: 1px solid #DEE2E6;
}

.agents-card .agents-img2 {
  width: Fixed (124px)px;
  height: Fixed (122px)px;
  padding: 13px 16px;
  border-right: 1px solid #DEE2E6;
}

.agents-card .agents-details .first-heading {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #313839;
}

.agents-details .location .location-icon {
  color: #1C9093;
  vertical-align: top;
}

.agents-details .location {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.agents-details .location .location-details {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #646C6D;
}

.agents-card .house-box {
  margin-top: 2px;
}

.agents-card .house-box .box1-img {
  margin-right: 6px;
  margin-left: 3px;
  margin-top: 2px;
}

.agents-card .house-box .box1-text {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
  margin-top: 5px;
  color: #646C6D;
  margin-right: 10px;
}

.agents-card .link {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #1877F2;
  margin-top: 8px;
}


/* ========================Agents  [ third section]================= */

.agents-featured {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.card-text .first-heading {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #313839;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text .location .location-icon {
  color: #1C9093;
}

.card-text .location .location-details {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #646C6D;
}

.card-text .link {
  white-space: nowrap;
}

.card-text .house-box .box1-img {
  margin-right: 6px;
  margin-left: 3px;
  margin-top: 2px;
}

.card-text .house-box .box1-text {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
  margin-top: 5px;
  color: #646C6D;
  margin-right: 10px;
  white-space: nowrap;
}

@media (max-width: 1200px) {
 
  .special-col {
    padding-left: 8px;
  }
}


/* ======================= Last Section================= */

.neighborhood_properties .heading {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 500;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.content_neignor a {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: #1877F2;
  text-decoration: none;
}





/* ========================Agent Profile================= */

.main-row {
  background: linear-gradient(180deg, rgba(28, 144, 147, 0.23) 0%, rgba(255, 255, 255, 0.31) 100%);
  border-radius: 5px;
}

.main-row .col-10 .img-box {
  border-radius: 5px;

}

.imgbox-text .first-box .heading {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #313839;
}

.imgbox-text .first-box .primium-card {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: #1C9093;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 9px 0px 4px !important;
  margin-bottom: 3px !important;
}

.list-agent .primium-card {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  color: #1C9093;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0px 9px 0px 4px !important;
  margin-bottom: 3px !important;
  white-space: nowarp;
}

.agentlist.pera {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-height: 3em;
  line-height: 1.5em;
  padding-right: 50px !important;
}

.list-agent button {
  padding: .3rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.list-agent .btn-1 {
  padding: .3rem 1rem;
  background: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.imgbox-text .second-box .location span {
  color: #1C9093;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  margin-right: 2px;
}

.imgbox-text .second-box .location {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #646C6D;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Adjust number of lines to show when clamped */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6em;
  transition: max-height 0.5s ease-in-out;
}

.imgbox-text .third-box {
  margin-left: 2px !important;
  flex-wrap: wrap;
  gap: 10px 0px;
  margin-top: 5px;
}

.imgbox-text .third-box .house-box {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #646C6D;
}

.imgbox-text .third-box .house-box span {
  margin-right: 8px;
}

.main-row .share-btn {
  background-color: #1C9093;
  color: #FFFFFF;
  padding: 8px 20px;
  gap: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.second-row .properties {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}



.house-card {
  border: 1px solid #DCDCDC;
  border-radius: 5px;
}

.house-card .house-box .house-img {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
  color: #646C6D;
}

.house-card .house-box .house-img span {
  width: 27px;
  height: 27px;
  border-radius: 49%;
  background-color: #F1FFFF;
  margin-right: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.house-card .house-box a {
  text-decoration: none;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: #1877F2;
}

.card-details .details-box {
  width: 94px;
  height: 56px;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
}


.card-details .quantity {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 18.85px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #1C9093;
}

.card-details .house-type {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 14.5px;
  letter-spacing: -0.005em;
  color: #646C6D;
  text-align: center;
}

.card-details .details-box1 {

  height: 56px;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
}

.card-details .house-type1 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 14.5px;
  letter-spacing: -0.005em;
  color: #646C6D;
}


.forth-row .team {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.team-card {
  background: linear-gradient(360deg, #FFFFFF 0%, #E7FEFF 100%);
  border: 1px solid #1C909382;
  border-radius: 10px;
}

.shadow-img {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 50%;
}

.team-card .user-name {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.team-card .user-post {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #1C9093;
}

.contact .contact-box {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #646C6D;
  border: 1px solid #A8A8A8;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  cursor: pointer;
}

.team-card .contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact .contact-box svg {
  font-size: 24px;
  margin-right: 4px;
}

.contact .contact-box span svg {
  font-size: 12px !important;
}

.call-box {
  padding: 5px 16px !important;
}


.fifth-row .agency {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
  display: flex;
  align-items: center !important;
}

.fifth-row .agency-text {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  /* height: 60px; */
}

.fifth-row .read-function {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1C9093;
  cursor: pointer;
  background: none;
  border: none;
}

.fifth-row .hr {
  border: 1.5px solid #F0EFFB;
  width: 96%;
}

.agency .user-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px #00000040;
  margin-right: 8px;
}

.agency .user-image img {
  width: 100%;
  height: 100%;
  vertical-align: 0%;
}









/* ======================== Properties ================= */


.properties h1 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839
}

/* ========================property-value================= */
.property-value {
  width: 100%;
  margin-top: 50px;
}

.property-value p {
  text-align: center;
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 600;
  color: #646C6D;
}



.value-text {
  margin-top: 30px;
}

.p-v {
  margin-top: 50px;
}

.property-card p {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
}

.value-text h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1C9093;
}

.value-text p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #313839;
}

.property-value .row .col-lg-5 {
  margin-top: 20px;
}

.property-value .value img {
  width: 600px;
  height: 506px;
  left: 844px;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
}

.property-value .value-img {
  height: 506px
}

.value-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ========================Currently-markit================= */
.currently {
  display: flex;
  align-items: center;
}

.markit .row .col-lg-12 {
  width: 100%;

  border-radius: 5px;
  background: #DEDEDE;
}

.markit .row .col-lg-12 .markit-off {
  height: 21px;
  width: 167px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
  color: #FF2E00;
  background: white;
  margin-right: 8px;
}

.markit-off p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #313839;
}

.markit-img img {
  width: 84px;
  height: 22px;
  margin-left: 5px;
}

/* =======================value-section================= */

.value-section {
  width: 100%;
  margin-top: 30px;
}

.value-section h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 39.01px;
  text-align: left;
  color: #646C6D;
}

.value-section .row .col-lg-12 {
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  background: #F1FFFF;
  padding-right: 90px;
  border: 1px solid #B4EDEE;
  border-radius: 5px;
}

.value-section .row .col-lg-12 .value-card {
  padding: 5px;
}

.mian-card {
  display: flex;
  justify-content: space-between;
}

.value-card h4 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  color: #646C6D;
  margin-top: 25px;
}

.value-card span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  color: #1C9093;
}

.value-card .estimated {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  text-align: left;
  color: #1C9093;
}

.value-card .pkr {
  width: 294px;
  height: 102px;
  top: 32px;
  gap: 0px;
  opacity: 0px;
  border-radius: 8px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.pkr h4 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 48.76px;
  text-align: left;
  color: #1C9093;
}

.pkr span {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;
  text-align: left;
}

.mian-card .line {
  height: 132px;
  border: 1px solid #BED8D9;
  margin-top: 5px;
}

/* ======================Blogs-section================= */

.blogs {
  width: 100%;
}


.blog-card {
  width: 100%;
  object-fit: cover;
}

.blog-card img {
  width: 100%;
  border-radius: 5px 0px 0px 5px;
}


.blog-text {
  padding: 13px;
  box-shadow: 0 0 20px lightgray;
  border-radius: 0 10px 10px 0px;

}

.blog-text h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  color: #313839;
}

.blog-text span {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
}

.blog-text p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: #464646;
}

.blog-text h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #1877F2;
}

/* ======================Blogs-listing================= */

.blog-listing {
  width: 100%;
}

.blog-listing .row {
  padding-right: 20px;
}

.Interior-card {
  width: 100%;
  gap: 0px;
  border-radius: 10px;
  box-shadow: 0 0 20px lightgray;
  ;
}

.Interior-card .Interior-img {
  width: 100%;
}

.Interior-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.img-heading {
  position: absolute;
  width: 142px;
  height: 29px;
  border-radius: 20px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  z-index: 111;
  margin: 27px;
  border-radius: 20px;
  background: white;
}

.img-heading h4 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  color: #626262;
  text-align: center;
  margin-top: 5px;
}

.listing-heading {
  padding: 15px;
  padding-left: 20px;
}

.listing-heading h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #313839;
}

.listing-heading p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #646C6D;
}

.list-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-span h2 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #1C9093;
}

.list-span h3 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
  color: #959595;
}

.list-span .read-more span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #1877F2;
}

.blog-listing .col-lg-3 .side-card {
  width: 100%;
  margin-top: 6px;
}

.listing .col-lg-4 img {
  position: relative;
}

.align {
  height: 16px;
  border: 1px solid;
  margin: auto;
}

.lgos {
  position: relative;
  width: 100%;
  height: 100%;

}

.lgos .position-absolute {
  background: var(--primary-color);
  color: white;
  top: 20px;
  right: -10px;
  border-radius: 5px;
  padding: .3rem;
  color: var(--Main-Color-White, #FFF);
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lgos .position-absolute .svg {
  top: 26px;
  right: 2px;
  position: absolute;
}

.lgos .list_img {
  width: 100%;
  position: absolute !important;
}

.listing-logos {
  position: absolute;
}

.lgs-icon {
  position: absolute;
  bottom: 0;
  display: flex;
}

.lgs-icon .tick {
  background-color: white;
  border-radius: 5px;

}

.lgs-icon .tick {
  margin-right: 4px;
}

.lgs-icon .tick span {
  font-family: var(--font-family);
  color: #1C9093;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.lgs-icon .tick1 span {
  font-family: var(--font-family);
  color: #1C9093;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: white;
  padding-left: 3px;
  border-radius: 5px;
  font-size: 10px;
}

.lgs {
  background-color: white;
  display: flex;
  justify-content: center;
  padding-left: 7px;
  border-radius: 5px;

}

.lgs div {
  margin-right: 8px;
  color: #1C9093;
}

.upr-section {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 20px lightgray;
}

.upr-section h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 42.62px;
  text-align: left;
  color: #313839;

}

.blog-icon {
  display: flex;
}

.blog-icon div {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-top: 10px;
  background: #1D9093;
  color: white;
}

.lower-section {
  width: 100%;
  border-radius: 10px;
  padding: 1px;
  margin-top: 25px;
  background: #1D9093;
}

.lower-img {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.lower-section h3 {
  font-family: var(--font-family);
  font-size: 36px;
  font-weight: 500;
  line-height: 43.88px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 30px;
}

.lower-section p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: white;
  text-align: center;
}

.lower-input h5 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: white;
}

.lower-input input {
  height: 40px;
  width: 285px;
  border: none;
  padding: 1rem;
  border-radius: 6px;
  outline: none;
}

.Blog-button {
  margin-top: 30px;
}

.project-listing .head_count {
  font-family: var(--font-family);
}

.project-listing .pagination_content {
  font-family: var(--font-family);
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.Blog-button button {
  padding: .3rem 6.4rem;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  background: none;
  color: white;
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 500;
}

.pading {
  padding: 1.2rem 10px;
}


/* ======================Blog-singal================= */

.blog-singal {
  width: 100%;
  margin-top: 30px;
}

.blog-singal .blog-span {
  display: flex;
  margin-top: 30px;
}

.blog-singal .blog-span span {
  display: flex;
  margin-right: 14px;
  align-items: center;
}

.blog-span h4 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #7D7D7D;
  margin-left: 7px;
  margin-top: 10px;
}

.singal-card h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 37.92px;
  text-align: left;
  color: #313839;
  margin-top: 20px;
}

.singal-card p {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #727272;
  margin-top: 20px;
}

.blog-li {
  margin-top: 23px;
}

.singal-card .blog-li ul li {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30.1px;
  color: #727272;
  margin: 10px;
}

.testimonial {
  width: 100%;
  margin-top: 30px;
}

.testimonial .test {
  width: 100%;
  padding: 10px;
  height: 412px;
  border-radius: 30px;
  background: #1C9093;
}

.test .test-icon {
  width: 68px;
  height: 68px;
  margin: auto;
  margin-top: 70px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.test p {
  text-align: center;
  font-family: var(--font-family);
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 30.1px;
  text-align: center;
  color: white;
}

.singal-div {
  display: flex;
  margin-top: 25px;
}

.singal-div h4 {
  font-family: var(--font-family);
  font-size: 30px;
  font-weight: 500;
  line-height: 36.57px;
  text-align: left;
  color: #303030;
}

.singal-icon {
  display: flex;
  padding-left: 12px;
}

.singal-icon div {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background: #1C9093;
  color: white;
}

.singal-input {
  display: flex;
  margin-top: 30px;
}

.singal-input .name input {
  height: 40px;
  width: 360px;
  border: 1px solid #D0D5DD;
  margin-right: 30px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family);
  padding: 13px;
  outline: none;
}

.singal-input .name h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-web {
  margin-top: 30px;
}

.singal-web h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-web input {
  height: 40px;
  width: 750px;
  border: 1px solid #D0D5DD;
  margin-right: 30px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font-family);
  padding: 13px;
  outline: none;
}

.describe-input {
  margin-top: 30px;
}

.describe-input h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.describe-input input {
  width: 750px;
  height: 122px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  outline: none;
}

.singal-card .checkbox {
  margin-top: 20px;
  display: flex;
}

.checkbox input {
  height: 20px;
  width: 20px;
  outline: none;
  margin-right: 10px;
}

.checkbox h6 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.singal-button {
  margin-top: 30px;
}

.singal-button button {
  padding: .3rem 3.3rem;
  background: #1C9093;
  color: white;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: none;
  border-radius: 4px;
  outline: none;
}

.singal-side-card {
  width: 100%;
}

.singal-side-card .serch-card {
  width: 100%;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
}

.serch-card input {
  border: none;
  padding: 10px;
  width: 100%;
  background-color: #EAEAEA;
  outline: none;
  color: white;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;

}

.serch-card input::placeholder {
  color: #4E4E4E;
}

.Recent-post {
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
  padding-left: 10px;

}

.recent-img {
  display: flex;

}

.recent-img .r-img {
  width: 100px !important;
  height: 100px !important;
}

.recent-img img {

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Recent-post h3 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 53.28px;
  color: #303030;
}

.recent-heading {
  width: max-content;
  display: flex;
  padding: 0 .5rem;
  flex-direction: column;
  width: 75%;
}

.recent-img .recent-heading h5 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.7px;
  text-align: left;
  color: #313839;
}

.blog-categories {
  width: 100%;
  margin-top: 25px;
  padding: 1.4rem;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
  border-radius: 10px;
}

.blog-categories h3 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 53.28px;
  text-align: left;
  color: #313839;
}

.blog-categories div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px dotted;
  padding: 6px;
}

.blog-categories div h5 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #313839;
}

.blog-categories div h6 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
  color: #313839;
  padding-top: 30px;
}

.blog-follow {
  border-radius: 10px;
  margin-top: 25px;
  padding: 1.2rem;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);

}

.blog-follow .follow-icon {
  display: flex;
}

.follow-icon div {
  height: 32px;
  width: 33px;
  border-radius: 100px;
  margin-right: 18px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  background-color: #1C9093;
  color: white;
}

.view-button {
  margin-top: 25px;
}

.view-button button {
  padding: .3rem 9rem;
  background: #1C9093;
  font-family: var(--font-family);
  font-size: 14px;
  border: none;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: white;
  border-radius: 4px;
}

/* ======================Blog-singal================= */

.listing-property {
  width: 100%;
}

.listing-property .new_search_result_box,
.listing-property .new_featured_box {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839
}

.lis-icon {
  display: flex;
  align-items: center !important;

}

.lis-icon span {
  padding: 10px;
}

.listing-mian {
  display: flex;

}

.b-heading span {
  height: 31px;
  width: 31px;
  border-radius: 50px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;

}

.li-icon span {
  margin-right: 10px;
  width: 21px;
}

.listing-mian .col-lg-8 div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.listing-mian h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.listing-mian span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #494949;
}

.listing-property .col-lg-6 h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.listing-img {
  display: flex;
}



.listing-img .col-lg-3 .img {
  border-radius: 3px;
}

.l-s h4 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.property-btn button {
  padding: .2rem 23px;
  display: flex;
  border: 1px solid #A8A8A8;
  align-items: center;
  background: none;
  border-radius: 3px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #494949;
}

.pro-div {
  border-radius: 5px;
  margin-top: 13px;
  padding-top: 7px;
  padding: 6px;
  padding-bottom: 10px;
  box-shadow: 0 0 20px rgba(211, 211, 211, 0.582);
}

.listing-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.listing-div div {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: #646C6D;
  border: 1px solid #646C6D !important;
  margin-right: 13px;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 3px;
  margin: .3rem;
}

.MuiPagination-ul .Mui-selected {
  background: var(--primary-color) !important;
}

.ls-img img {

  width: 100%;
}

/* ======================Map================= */
.map h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.map h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #646C6D;
}

.map span {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #A8A8A8;
}

/* ======================Blog-heading================= */

.B-heading h3 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.B-heading h4 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.B-heading p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

/* ======================News================= */
.news {
  position: relative;
}

.m-news {
  width: 100%;
  height: 305px;
  background-color: black;
  position: absolute;
  z-index: -1;

}

.news-dot {
  display: flex;
  justify-content: flex-end;
}

.news-dot div {
  height: 9px;
  width: 9px;
  margin-right: 6px;
  border-radius: 50px;
  background: white;

}


.news-card h3 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  text-align: left;
  color: #F7F7F4;
}

.n-h h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 900;
  line-height: 10px;
  text-align: left;
  color: #FDFDFD;
}

.ne-heading {
  display: flex;
  justify-content: space-between;
  padding: 1.4rem 0 0 0;
}

.ne-1 h3 {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 600;
  line-height: 18.29px;
  text-align: left;
  color: #313839;
}

.ne-1 h4 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #04594D;
}

.ns-heading h4 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #D5D5D5;
}

.ns-img img {
  width: 100%;
  height: 100%;

}

.ns-h {
  padding-top: 2rem;
  padding-left: 10px;
}

.ns-h h3 {
  font-family: var(--font-family);
  font-size: 20px;
  margin-top: 2.3rem;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: white;
}

.news-lower-section {
  width: 100%;

  margin-top: 60px;
  padding-top: 20px;
}

.low {
  display: flex;
}

.low img {
  width: 100%;
  height: 100%;
}

.punjab {
  width: 100%;

}

.brd {
  border-right: 1px solid #DBDBDB;
  height: 120px;
  margin-top: 20px;
}

.punjab h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: #313839;
}

.pu-span {
  display: flex;
}

.punjab-span h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  color: #676767;
  margin-right: 14px;
}

.punjab-span h5 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  color: #676767;
}

.punjab-span p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color:
    #04594D;
}

/* ======================latest-News================= */

.latest-news {
  width: 100%;

}

.latest-heading h3 {
  font-family: var(--font-family);
  font-size: 33.21px;
  font-weight: 400;
  line-height: 33.21px;
  text-align: left;
  margin-right: 16px;
}

.latest-heading {
  display: flex;
  align-items: center;
}

.latest-heading div {
  width: 551px;
  border: 1px solid #1A1A1A;
}


.box h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: #FDFDFD;
}

.box {
  padding: 0 12px;
}

.late-heading h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: left;
  color: #313839;
}

.late-heading {
  padding: 10px 20px;
  box-shadow: 0 0 20px lightgrey;
  border-radius: 8px;
}

.late-heading p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #04594D;
}

.late-heading h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  text-align: left;
  color: #646C6D;
}

.my-news {
  padding: 25px 10px 8px 10px;
}

.latest-news .row .col-lg-3 {
  border: 1px solid;
}

.mandorty {
  padding: 15px 17px 5px 17px;
}

.side-late-heading h3 {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #1A1A1A;
}

.side-late-heading h4 {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #04594D;
}

.m-tags {
  padding: 0 0 0 25px;

}

.side-latest-card {
  padding: 5px;
  box-shadow: 0 0 20px lightgrey;
  border-radius: 10px;
}





/* ======================== Housing Society Details Expand [ Banner ] ================= */

.front-img {
  position: relative;
  height: 313px;
  border-radius:5px;
  overflow:hidden;
}

.front-img .top-icon-box {
  position: absolute;
  width: 142px;
  height: 17px;
  z-index: 999;
  top: 12px;
  left: 12px;
  display: flex;
  gap: 8px;
}

.top-icon-box .first-box {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.top-icon-box .first-box .camera {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #1C9093;
  border-right: 1px solid #025A5C;
  padding-right: 5px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.top-icon-box .first-box .video {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #1C9093;
  display: flex;
  align-items: center;
}

.top-icon-box .second-box {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #1C9093;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 4px 6px;
}

.front-img .bottom-icon-box {
  position: absolute;
  z-index: 999;
  bottom: 8px;
  left: 8px;
  border-radius: 5px;
  padding: 4px 8px;
}

.details-card-box {
  border: 1.5px solid #F0EFFB;
  border-radius: 8px;
}

.location .society-name {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #313839;
}

.location .society-location {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #646C6D;
}

.location-sharing-icons .icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1.5px solid #A8A8A8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1C9093;
  font-size: 16px;
}

.location-box .col-12 .house-price {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1px;
  text-align: left;
  color: #1C9093;
}

.details-card-box .developer-details {
  border: 1px solid #1C9093;
  border-radius: 5px;
}

.details-card-box .developer-details .offers {
  border-left: 1px solid #1C9093;
  padding-left: 40px;
}


.details-card-box .developer-details .developer .heading {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #313839;
}

.developer-details .developer .company-name {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1C9093;
}

.details-card-box .developer-details .offers .heading {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #313839;
}

.offers .offered-items .items {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #646C6D;
  display: flex;
  align-items: baseline;
  margin-right: 20px;
}

.offers .offered-items .items img {
  margin-right: 4px;
}

.details-card-box .slot {
  background-color: #F1FFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #1D90931A;
  padding: 26px 18px;
}

.details-card-box .slot .heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  color: #646C6D;
  display: flex;
  align-items: baseline;
}

.details-card-box .slot .heading img {
  margin-right: 10px;
}

.details-card-box .slot .price {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  color: #646C6D;
  display: flex;
  align-items: baseline;
}

.details-card-box .slot .price span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #646C6D;
}

.details-card-box .house-slot {
  flex-direction: column;
}

.housing-society .ground_parent{
  display: grid;
  gap: 12px 0;
}

.details-card-box .house-slot .Ground-floor {
  width: -webkit-fill-available;
}

.details-card-box .house-slot .Ground-floor .heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  color: #646C6D;
}

.house-slot .Ground-floor {
  border: 1px solid #AFF2F4;
  border-radius: 5px;
  background-color: white;
  margin-top: 25px;
}

.house-slot .Ground-floor .hr-line {
  border: 1.5px solid #F0EFFB
}

.floor-details {
  gap: 20px;
  flex-wrap: nowrap;
  width: max-content;
}

.floor-details .details-small-card {
  width: max-content;
}

.floor-details .details {
  width: max-content;
}

.floor-details .details .heading-box {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #646C6D;
}


.floor-details .details .number {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  color: #646C6D;
}

.floor-details .details .link-box {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  color: #1877F2;
}

.Ground-floor .details .link-box div {
  text-decoration: none;
  white-space: normal;
  cursor: pointer;
}

.details-card-box .slot-text {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
}

.read-button .slot-read-function {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #1C9093;
  border: none;
  background-color: transparent;
}

.details-card-box .hr-line {
  border: 1.5px solid #F0EFFB
}

.shadowed-main-feature {
  box-shadow: 0px 4px 4px 0px #1D90931A;
}

.mian_feature .aminities-items {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.mian_feature .aminities-items img {
  margin-right: 4px;
}

.housing-society .floor-heading {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.details-card-box .plan-box {
  border: 1.5px solid #F0EFFB;
  border-radius: 8px;
}

.details-card-box .plan-box .options-box {
  padding: 0px 50px 8px 0px;
}

.plan-box .options-box .option {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #FFFFFF;
  border: 1px solid;
  background-color: #1C9093;
  padding: .4rem .8rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}


.plan-box .options-box .option1 {
  border-bottom: 1px solid #A8A8A8;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #646C6D;
  padding: 13px 15px 13px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.plan-box .options-box .option img {
  margin-right: 5px;
}

.plan-box .options-box .option1 img {
  margin-right: 5px;
}

.development-upadtes .development-heading {
  font-family: var(--font-family);
  font-size: 28px;
  font-weight: 600;
  line-height: 47px;
  text-align: left;
  color: #313839;
}

.development-upadtes .development-plan-box .arrow {
  color: #1C9093;
  font-size: 50px;
  margin-top: 24px;
}

.details-card-box .development-upadtes .development-plan-box {
  border: 1.5px solid #F0EFFB;
  border-radius: 8px;
}

.development-plan-box .month-box .month {
  font-family: var(--font-family);
  width: 85px;
  height: 85px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #1C9093;
  border-radius: 50%;
  background-color: #F1FFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.development-plan-box .month-box {
  width: 100px;
  height: 95px;
  border-radius: 50%;
  border: 1px solid #1C9093;
  display: flex;
  justify-content: center;
  align-items: center;
}

.development-plan-box .month-circle {
  width: 79px;
  height: 79px;
  background-color: #E3FEFF;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #1C9093;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.development-plan-box .line {
  width: 4%;
  border: 1px solid #1C9093;
}

.development-plan-box .development-card {
  width: 522px;
  height: 157px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
  margin-top: 55px;
  margin-bottom: 4px;
}

.development-plan-box .development-card .development-img-box {
  width: 232px;
  height: 157px;
  position: relative;
}

.development-plan-box .development-card .development-card-details {
  width: max-content;
}

.development-plan-box .development-card .development-card-details .heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #313839;
}

.development-card .development-card-details .posted-date {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #646C6D;
  display: flex;
  align-items: center;
}

.development-card .development-card-details .posted-date span {
  display: flex;
  align-items: center;
  color: #1C9093;
  margin-right: 10px;
  margin-left: 6px;
}

.development-card .development-img-box .pic-box {
  width: 75px;
  height: 17px;
  background-color: white;
  position: absolute;
  z-index: 99;
  top: 8px;
  left: 6px;
  border-radius: 3px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 5px;
}

.development-card .development-img-box .pic-box .camera-box,
.development-card .development-img-box .pic-box .video-box {
  display: flex;
  align-items: center;
  color: #1C9093;
  font-size: 10px;
}

.development-card .development-img-box .pic-box .video-box {
  font-size: 16px !important;
}

.development-card .development-img-box .pic-box .camera-box {
  margin-left: 3px !important;
}

.development-card .development-img-box .pic-box .camera-box {
  width: 30px;

}

.development-card .development-img-box .pic-box .video-box {
  width: 25px;

}

.development-card .development-img-box .pic-box .line {
  content: "";
  width: 1px;
  height: 12px;
  background-color: #1C9093;
  margin-right: 3px;
  display: block;
}

.modal-box .modal-data {
  padding: 6px 20px;
}

.modal-box .modal-data .active {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
  border-bottom: 2px solid #1C9093;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-box .modal-data .tab {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #A8A8A8;
  border-bottom: 2px solid #A8A8A8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.modal-box .modal-data .new_img-box {
  margin: 20px 0 13px 0;
  height: 380px;
  width: 370px;
  border-radius: 5px;
  overflow: hidden;
}

.modal-box .modal-data .new_img-box img {
  object-fit: cover;
}

.modal-box .modal-data .small-img-box .box {
  padding: 0;
  margin-right: 6px;
  margin-bottom: 4px;
}

.report-button {
  border: 1px solid #1C9093;
  padding: 7px 20px 7px 20px;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1C9093;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Popular-searches-para {
  margin-top: 63px;
}

.modal-box {
  background-color: white;
  border-radius: 5px;
  padding-bottom: 6px;
}

.modal-box .modal-heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #494949;
}

.modal-box .close-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ECECEC;
  color: #80889E;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-box .img-box {
  border-radius: 10px;
  margin: 20px;
  width: 540px;
  height: 360px;
  border-radius: 5px;
}

.privce-policeys h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;

}

.priv-policy p {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B6B6B;
  font-family: var(--font-family);
  background-color: #F9F9F9;
  margin-bottom: 0;
}

.priv-policy {
  background-color: #F9F9F9;
  padding-top: 10px;
  padding-bottom: 15px;
}

.priv-policy h2 {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 63.98px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
}

.priv-policy h4 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #646C6D;
}

.priv-policy h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B6B6B;
}

.priv-policy ul li {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
  margin-top: 25px;
}

.priWill-policy p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B6B6B;
}

.priWill-policy {
  background-color: #F9F9F9;
  padding-top: 10px;
  padding-bottom: 15px;
}

.policy h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #646C6D;

}

.policy ul li {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6B6B6B;
}

.policy ul li span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: justified;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;

}

/* ////////////////////////// [ dashboard main ] ///////////////////////// */

.dashboard_main .new_dashboard_listing_page {
  padding: 0px 10px 23px 2px;
  display: flex;
  gap: 8px 0;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D8D8D8;

}

.dashboard_main .new_dashboard_listing_page .box {
  display: flex;
  align-items: center;
}

.dashboard_main .new_dashboard_listing_page .box .title {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #646C6D;

}

.dashboard_main .new_dashboard_listing_page .box .count {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #313839;
  margin-top: -10px !important;
}


.satate_dashboard {
  background-color: white;
  border-radius: 10px;
  height: 400px;
  margin-bottom: 30px;
  border: 1px solid #D8D8D8;
}

.satate_dashboard .new-scroll-box {
  overflow: scroll;
}

.satate_dashboard .new-scroll-box::-webkit-scrollbar {
  height: 3px;
}

.satate_dashboard .new-scroll-box::-webkit-scrollbar-thumb {
  background-color: #1C9093;
  border-radius: 25px;
}

.satate_dashboard .dashboard_listing_page {
  display: flex;
  /* gap: 35px; */
  width: max-content;
  overflow: hidden;
  /* padding: 8px 0 10px 0; */
}

.satate_dashboard .dashboard_listing_page .box {
  display: flex;
  align-items: center;
  width: 170px;
  cursor: pointer;
}


.satate_dashboard .dashboard_listing_page .box .title,
.satate_dashboard .dashboard_listing_page .box .count {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #BDBDBD;
}

.satate_dashboard .dashboard_listing_page .box h6 {
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8B8B8B;
  margin-top: 14px;
}

.satate_dashboard .chart {
  height: 70%;
}

.satate_dashboard .chart h6,
.satate_dashboard .chart p {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #313839;
  margin-bottom: 0;
}

.satate_dashboard .chart p {
  color: #646C6D;
}


/*/////////////////////////////////////// [ Gallery Modal ] //////////////////////*/

.head-box {
  display: flex;
  justify-content: space-between;
}


.heading {
  font-family: var(--font-family);
  font-size: var(--main-heading);
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 12px;
  color: #313839;
  text-align: left;
}

.close-btn {
  width: 24px !important;
  height: 24px !important;
  border: none;
  background-color: #ECECEC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  color: #80889E;
}

.close-btn:hover {
  rotate: -90deg;
  transition: all 0.4s;
}

.gallery-input-card .price {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28.5px;
  text-align: left;
}

.gallery-input-card .call-btn {
  width: 100%;
  font-size: 16px;
  font-family: var(--font-family);
  padding: 0px 15px;
  border-radius: 4px;
  color: white;
  background-color: #1C9093;
  border: none;
  padding: 3px 0;
  display: flex;
  justify-content: center !important;
  align-items: baseline !important;
}

.gallery-input-card .name {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.gallery-input-card .owner {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #646C6D;
}

.gallery-input-card .input-box {
  height: 315px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
}

/* .gallery-input-card .input-box::-webkit-scrollbar {
  display: none;
} */

.gallery-input-card .input-label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  margin: 12px 0 4px 0;
}

.gallery-input-card .input::placeholder {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #A8A8A8;
}


.gallery-input-card .number {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #545468;
  cursor: pointer;
}

.gallery-input-card .message-input {
  resize: none;
}

.gallery-input-card .send-btn {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background-color: #1C9093;
  color: #FFFFFF;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 7px 20px;
}


.gallery-input-card .send-btn:disabled {
  background-color: #B2D9D9;
  color: white;
  cursor: not-allowed;
}


.gallery-input-card .form-check-input {
  border-color: #A8A8A8 !important;
}

.gallery-input-card .form-check-input:checked {
  background-color: #1C9093;
  border-color: #1C9093 !important;
  box-shadow: none;
}


.gallery-input-card .alert-msg {
  color: red;
  font-size: 10px;
}

.gallery-img-card .tab {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
  cursor: pointer;

}

.gallery-img-card .active {
  border-bottom: 1.5px solid #1C9093;
}

.gallery-img-card .dis-active {
  color: #A8A8A8;
  border-bottom: 1.5px solid #C7C8C9;
}

.gallery-img-card .col-10 .img-box {
  height: 325px;
  width: 250px;
}

.gallery-img-card .col-10 .img-box img {
  object-fit: cover;
}

.gallery-img-card .main-img-box {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.gallery-img-card .main-img-box img {
  cursor: pointer;
  margin: 0 3px;
}

.gallery-img-card .main-img-box::-webkit-scrollbar {
  display: none;
}


.gallery-img-card .option .text {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #646C6D;
  margin: 10px 0 6px -8px;
}

.gallery-img-card .option .form-check-label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 2px 0 0 6px;
  font-size: 12px !important;
  color: #646C6D;
}

.gallery-img-card .option .form-check {
  display: flex;
  align-items: center;
}

.gallery-img-card .last-check {
  display: flex;
}

.gallery-img-card label.form-check-label {
  margin: 2px 0 0 10px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #646C6D;
}

.scroll-box-y {
  overflow-y: auto;
  height: 80vh;
}

.scroll-box-y::-webkit-scrollbar {
  width: 4px;
}

.scroll-box-y::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #1C9093;
  color: #1C9093;
}


.scroll-box-y::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}


/* ///////////////////////////////////////////////////////  Call Card ///////////////////////////////////////////////////////////// */


.flex-class {
  display: flex;
  justify-content: center;
}

.call-card {
  background-color: white;
  border-radius: 5px;
  overflow-y: scroll;
}

.call-card::-webkit-scrollbar {
  display: none;
}

.call-card .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 18px;
}


.call-card .head .heading {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #494949;
}

.call-cancel-btn {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ECECEC;
  color: #80889E;
  padding: 6px;
}

.call-card .hr {
  width: 100%;
  border: 1px solid #E1E2E9
}

.call-card-dealer-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.call-card-dealer-details .img-box .img {
  width: 113px;
  height: 113px;
  border-radius: 50%;
}

.call-card-dealer-details .dealer-company-name {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.call-card-dealer-details .dealer-name {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.call-card-dealer-details .dealer-id {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #313839;
}

.contact-box .contact-col {
  padding: 20px 8px 20px 16px;
}

.contact-box .contact-col-1 {
  padding: 20px 16px 20px 8px;
}

.contact-box .mobile-box {
  background-color: #F1FFFF;
  border-radius: 10px;
  padding: 20px 0;
}

.contact-box .mobile-box-1 {
  background-color: #F1FFFF;
  border-radius: 10px;
  padding: 24px 0 20px 0;
}

.mobile-box .img-box span,
.mobile-box-1 .img-box span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #313839;
}

.contact-box .mobile-box .mobile-number,
.contact-box .mobile-box-1 .mobile-number {
  margin-top: 12px;
}

.contact-box .mobile-box .mobile-number span,
.contact-box .mobile-box-1 .mobile-number span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1137FF;
}


.call-card .last-details .note {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #646C6D;
}

.call-card .last-details .note span {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #313839;
}

.new_search_bar {
  position: absolute;
  top: 60px;
  right: 3px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #A8A8A8;
}

.new_search_bar input,
.new_search_bar input::placeholder {
  font-size: 14px !important;
}

.inp_login select {

  outline: none;
}

.inp_login select option {
  background-color: white;
  transition: all 0.2s linear;
  cursor: pointer;
}

.inp_login select option:hover {
  background-color: #1C9093 !important;
}


.agency-text.clamped {
  -webkit-line-clamp: 3;
  /* Show only 3 lines */
  line-clamp: 3;
}

.agency-text.expanded {
  -webkit-line-clamp: none;
  /* Show full text */
  line-clamp: none;
}

.read-more-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;
}

.read-function {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.agency-text {

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}




.entrance-container .animated-img {
  position: absolute;
  animation: move-infinite 10s linear infinite;
}

@keyframes move-infinite {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }

}


.new_search_inp_populer {
  border-bottom: 1px solid #D8D8D8;

  transition: all 0.4s linear;
}

.new_search_inp_populer input {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0;
  color: #A8A8A8;
  margin: 0;
  padding: 0.3rem;
  font-size: 16px;
  width: 100%;
  margin-top: 13px;
}

.new_search_inp_populer input::placeholder {
  color: #A8A8A8;
  font-size: 16px;
}

/* /////////////////////////////////////////// [ instant modal ] /////////////////////////////////////////////// */


.instant_modal {
  background-color: white;
  border-radius: 12px;
  padding: 28px 30px 20px 30px;
}

.instant_modal .text {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #646C6D;
  margin: 18px 0;
}


.instant_modal .input_container input {
  margin-top: 0;
  border: 1px solid #D0D5DD !important;
  border-radius: 6px !important;
  margin-right: 10px;
}


.instant_modal .instant_input_form {
  height: 310px;
  padding-right: 5px;
}


.instant_modal .submit_btn {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  background-color: #1C9093;
  border-radius: 4px;
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent_card_text_box {
  width: 60%;
}


.new_active_tab {
  background: linear-gradient(to bottom, #1c909321 0%, #FFFFFF 40%);
  border-top: 5px solid #1C9093;
  box-shadow: 2px 2px 8px rgba(169, 169, 169, 0.5);

}


.disabled-input-box {
  /* cursor: not-allowed; */
  opacity: 0.5;
}

.disabled-input-box input {
  background-color: transparent;
}

.orderStatus {
  text-transform: capitalize;
}

.cursors {
  cursor: context-menu;
}

.new_listing_heading {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}



.homeComprehen::-webkit-scrollbar {
  width: 4px !important;
  display: flex !important;

}

.homeComprehen::-webkit-scrollbar-thumb {
  background-color: #1c9093c4 !important;
  border-radius: 10px !important;

}

.homeComprehen::-webkit-scrollbar-track {
  background-color: #fff !important;
  border-radius: 10px !important;



}

.homeComprehen h1:first-child {
  margin-top: 0;
}

.custom-prev-arrow,
.custom-next-arrow {
  background-color: #fff;
  border: none;
  padding: 10px;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  font-size: 20px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A8A8A8;
  font-size: 24px;
  box-shadow: red;
}

.custom-prev-arrow {
  left: -24px;
  left: -24px;
  /* Adjust based on your design */
}

.custom-next-arrow {
  right: -24px;
  right: -24px;
  /* Adjust based on your design */
}


.next-button {
  background-color: #1C9093;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 2 0px;
}

.next-button:hover {
  background-color: #137a77;
}



.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
 
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6em;
  transition: max-height 0.5s ease-in-out;
}

.show-full-text {
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  overflow: visible;
  max-height: 1000px;
  transition: max-height 0.3s ease-in-out;
}

.menueNew {
  font-size: 22px !important;
  line-height: 19px;



}

.NavHaeder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  align-items: center;
}

.projectSearch {
  display: none;
}

.footerUL li {
  display: flex;
}

.commonPopup::-webkit-scrollbar {
  width: 4px;
}

.commonPopup::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #1C9093;
  color: #1C9093;
}


.commonPopup::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}

.imgProperty {
  width: 100%;
  overflow: hidden;
  height: 200px;
}

.imgProperty img {
  width: 100% !important;

  height: 100% !important;
}

.priceHeading span {
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.entrance-container .main-img {
  position: relative;
  overflow: hidden;
  /* Ensures images don't overflow */
  width: 100%;
  /* Adjust as needed */
  height: 80px;
  /* Adjust height to fit your design */
}

.marquee-w {
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.marquee {
  position: absolute;
  display: block;
  margin: auto auto;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
  height: 100%;
}

.marquee img {
  margin: 0 30px;
  width: unset !important;
  height: unset !important;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 100%;
  height: 100%;
  font-size: 58px;
  font-weight: 700;
  line-height: 69.6px;
  color: #1c9093;
  animation: marquee 21s linear infinite;
}
.marquee2 span {
  animation-delay: 10.5s;
  display: inline-block;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.vendorAlert .MuiPaper-root {
  width: 100%;
  font-size: 12px;
  padding: 0px 16px;
  padding-left: 0;
  border: none;
  margin-top: -16px;

}

.vendorAlert .MuiAlert-icon {
  margin-right: 6px;
  /* margin-left: 6px; */
}

.vendorAlert .MuiAlert-icon svg {
  width: 1rem;
  height: 1rem;
}

.vendorAlert .marquee-w {
  height: 100%;
  left: 47%;
}

.vendorAlert .marquee-w span {
  font-size: 12px;
  color: rgb(102, 60, 0);
  line-height: 10px;
  animation: Valert 30s linear infinite;
  text-transform: none;
}

.vendorAlert .MuiAlert-message {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.vendorAlert .MuiAlert-message strong {
  white-space: nowrap;
}

@keyframes Valert {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
.sideArrow{
  position: absolute;
  width: 100%;
  height: 100%;
}
.sideArrow svg{
  font-size: 35px;
  color: #575555;
  cursor: pointer;
  background-color: rgba(0,0,0,.2);
  border-radius: .4rem;
  /* padding: .5rem; */
 transition: all .2s linear;
  color: white;
}
.sideArrow svg:hover{
  
  background-color: rgba(0,0,0,.5);
  
}
.propertySlide .slick-dots{
white-space: nowrap;
  overflow-x: auto;
  position: unset;
  padding-bottom: 5px;
} 
.propertySlide .slick-dots li{
width: unset;
height: 100%;
opacity: .4;
} 
.propertySlide .slick-dots .slick-active{

opacity: 1;
} 

.propertySlide .slick-dots::-webkit-scrollbar {
  height: 6px;
}

.propertySlide .slick-dots::-webkit-scrollbar-thumb {
  background-color: #1C9093;
  border-radius: 25px;
}

.propertySlide .slick-dots::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}

.identification{
  display: flex;
  flex-wrap: wrap;
}
.card_modal, .commonPopup{
  overflow-y: auto;
  max-height:100%;
}
/* for desktop */
.whatsppDiv{
  position: fixed;
  bottom: 0px;
  right: 10px;
  z-index: 111;
}
.whatsppDiv p{
  font-size: 12px;
  color: var(--primary, #1c9093);
}

   .whatsapp_float {
    position: fixed;
    width: 42px;
    height: 43px;
    bottom: 40px;
    right: 16px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 24px;
    box-shadow: 0 0 0 0 #42db87;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    -webkit-animation:pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1) ;
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
}
@keyframes pulsing {
  100%{
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
.whatsapp_float:hover{
  color: #fff !important;
}
.AreaSize span{
  margin-right: 3px;
}
.profileImg img{
  height: 113px !important;
}
.pakImg img{
  height: unset !important;
  width: unset !important;
}
.addPropInp input[type="number"]::-webkit-inner-spin-button,
.addPropInp input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    margin: 0;
}

.addPropInp input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
.priceCount{
  position: absolute;
  top: 100%;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  color: #A8A8A8;
  line-height: 20px;
  right: 0;


}
.addProperties{
  height: calc(100vh - 160px);

  overflow-y: auto;
}
.addProperties::-webkit-scrollbar {
  width: 4px;
}

.addProperties::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #1C9093;
  color: #1C9093;
}


.addProperties::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}
.addProperties1{
  height: calc(100vh - 310px);

  overflow-y: auto;
}
.addProperties1::-webkit-scrollbar {
  width: 4px;
}

.addProperties1::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #1C9093;
  color: #1C9093;
}


.addProperties1::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 25px;
}
.iconHeading{
  display: flex;
  align-items: center;
}
.iconHeading svg{
  margin-right: 5px;
}
.profileButoon{
  position: absolute;
  top: -43px;
  right: 0;

}
.profileButoon11{
  margin-bottom: 20px;

}
.extraBox{
  cursor: context-menu !important;
}
.projectBanner{
min-height: 320px;
padding: 1rem 3rem !important;
}
.projectDetailImgs{
  width: 100% ;
  height: 400px;
  object-fit: cover;
}
.heading img{
  width: unset !important;
}
.agentFeature{
  gap: 60px 0;
}
.uploadAgentimage{
  height: 115px;
  margin-top: 9px;
}
/*////////////////////////////////////// [ Media Query ] ///////////////////////////*/

@media screen and (max-width:1400px) {
  .card_modal {
    width: 80% !important;
  }

}
@media screen and (min-width:1400px) {
  .card_modal {
    width:60% !important;
  }

}
@media screen and (max-width:1350px) {
  .agent_card_text_box {
    width: 50%;
  }

}

@media screen and (max-width:1280px) {
  .agent_card_text_box {
    width: 50%;
  }

  .property-item small {
    font-size: 12px;
  }

  .property-item .card_footer span {
    font-size: 12px;
  }


}

@media screen and (max-width:1200px) {
  .enterNextImg {
    margin-left: -27px !important;
  }

  .inp_bar input {
    width: 242px !important;
  }

  .banner_section .new-content {
    padding: 3rem 1rem !important;
  }

  .banner_section .new-tab-section {
    flex-wrap: wrap;
    height: unset;
    padding: 10px 20px;
  }

  .select_btn_parent {
    width: -webkit-fill-available;
    margin-top: 10px;
  }

  .tab_section .input_new_parent {
    width: -webkit-fill-available;
    justify-content: space-between;
  }
}


@media screen and (max-width:1100px) {

  .banner_section .new_content_box {
    width: 100%;
    padding: 3rem 1rem;
  }
}




@media screen and (max-width:1024px) {
  .nav_ul ul li {
    font-size: 13px;
    padding: 0;
  }

  .property-item small {
    font-size: 13px;
  }

  .property-item .card_footer span {
    font-size: 15px;
  }

  .scroll-box .new_scroll_row {
    width: 110%;
  }

  .tab_section .d-flex {
    width: 100% !important;
  }

  .select_banner {
    width: 50%;
  }

  .select_banner input {
    width: -webkit-fill-available !important;
  }

  .banner_section .select_banner_btn {
    margin-top: 12px;
  }

  .main_banner_home .agen_content {
    width: 100% !important;
  }

  .main_banner_home img {
    width: 100%;
    height: 100%;
  }

  .banner_section .tab_pan_project {
    width: 90%;
    margin: 0 auto;
  }


  .banner_section .tab_pan_project .search_bar_main {
    width: 100%;
    display: block;
  }


  .banner_section .tab_pan_project .btn_box {
    margin-top: 30px;
  }

  .new_tab_banner {
    width: 100%;
  }

  .new_tab_banner .tab_section {
    width: 95% !important;
  }

  .tab_section .input_new_parent {
    flex-wrap: wrap;
    gap: 10px 0px;
  }

  .new_tab_banner .select_banner {
    width: unset;
  }

  .new_tab_banner .third-line {
    display: none !important;
  }

  .input_new_parent .home_icon {
    margin-right: 10px;
    margin-left: 8px;
  }


}

@media screen and (max-width:992px) {
  
  .profileButoon{
   
    top: -115px;
  
  
  }
  .enterNextImg {
    margin-left: -23px !important;
  }

  .aboutIMHS {
    padding: 0 !important;
  }

  .btn_popular button {
    padding: .2rem 12px;
  }

  .nav_ul {
    padding-left: 1rem;
  }

  .nav_ul ul li {
    font-size: 13px;
    padding: 0;
    margin-left: .25rem;
  }

  .card_main_listing .dashboard_listing_page {
    justify-content: space-around;
    align-items: center;
  }

  .card_main_listing {
    min-height: auto;
  }

  .table_data {
    width: max-content !important;
  }

  .Popular-searches-para {
    margin-top: 0;
  }

  .dashboard_main .new_dashboard_listing_page {
    padding: 10px 10px 10px 2px !important;
  }

  .new_location_map {
    width: 100% !important;
  }

  .scroll-box .new_scroll_row {
    width: 900px !important;
  }

  .inp_bar input {
    width: 200px !important;
  }

  .lgos {
    height: 300px !important;
  }

  .intant-value h2 br {
    display: none;
  }

  .property-value .value-img {
    height: 300px !important
  }

  .listing_main_img img {
    height: 400px;
  }

  .input_new_parent .home_icon {
    margin-right: 10px;
    margin-left: 0px;
  }

  .new_special_col {
    padding-left: 0 !important;
  }

  .new_changed_box {
    flex-direction: column;
    align-items: center;
  }

  .new_changed_box .sub_changed_box {
    text-align: center
  }



}

@media screen and (max-width:850px) {
  .mor_option {
    width: 100%;
  }

  .tab_pan_main {
    width: 100%;

  }

  .select_banner input {
    width: 250px;
  }



  .new_card_main_listing {
    width: 900px;
  }

  .card_modal,
  .call_modal {
    width: 96% !important;
  }

  .new_tab_banner .second-line {
    display: none !important;
  }

  .new_tab_banner .third-line {
    display: inline !important;
  }






}

@media screen and (max-width:767px) {
  .projectSearchnew{
    width: 100%;
  }
  .projectSearchnew input{
    width: 100%;
  }
  
  .whatsapp-icon {
    margin-top: 10px;
}

.whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
}

  .marquee span {
    font-size: 30px;
  }

  .marquee img {
    height: 30px !important;
  }

  .entrance-container .main-img {
    height: 60px;
  }

  .third-row .special-col {
    padding-right: 0 !important;
  }

  .third-row .special-col-1 {
    padding-left: 0 !important;
  }

  
  .item-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-x: hidden;
    padding: 5px 0;
  }

  .first-item,
  .second-item {
    flex: 0 1 auto;
    text-align: center;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: 1px solid lightgray;
  }

  .second-item {
    border-bottom: 2px solid #025A5C;
  }

  .second-item .item-line {
    display: none;
  }

  .first-item .item-third {
    margin: 0;
  }

  .details-col {

    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    margin: 0 auto;
  }

  .colored-box {
    margin: 20px auto;
    max-width: 100%;
    text-align: center;
  }

  .colorbox-col {
    padding: 0 25px !important;
  }

  .main-parabox {
    margin: 0 auto;
    max-width: 100%;

  }



  .first-item,
  .second-item {
    flex: 0 1 auto;
    text-align: center;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
  }

  .second-item {
    border-bottom: 2px solid #025A5C;
  }

  .second-item .item-line {
    display: none;
  }

  .first-item .item-third {
    margin: 0;
  }

  .enterNextImg {
    margin-left: -17px !important;
  }

  .nav_ul ul {
    display: none !important;
    padding-left: 1rem;
  }

  .menu_bar_btn {
    display: block;
  }

  .textbox-col {
    padding-left: 30px;
    padding-right: 30px;
  }

  .SeoSecImg {
    height: 300px !important;
  }

  .SeoSecImg img {
    height: 100% !important;
    object-fit: contain;
  }

  .projectSearch {
    display: inline;
  }

  /* .projectSearchnew {
    display: none;

  } */

  .mor_option .more_div {
    width: 49%;

  }

  .main_sidebarRight {
    height: 110vh !important;
  }


  .xyz::-webkit-scrollbar {
    height: 3px;
  }

  .xyz::-webkit-scrollbar-thumb {
    background-color: #1C9093;
    border-radius: 25px;
  }



  .agency_staff .content_box .profile_section {
    /* flex-direction: column; */
    align-items: center;
  }

  /* .invidual-btn {
    margin: 0 auto;
  } */

  .buy_product .table_row h5 {
    padding-left: 4px !important;
  }

  .new_table_body {
    gap: 40px;
    padding-left: 17px !important;
  }

  .table_header {
    position: sticky;
    top: 0;
    background-color: white;

    z-index: 10;

  }

  .buy_product .table_body .new_table_body h6 {
    width: 100px;
  }

  .table_data .sticky-header .h5 {
    padding-left: 49px !important;
  }

  .new_table_data {
    width: fit-content !important;
  }

  .plan-box .options-box .option1 {
    border-bottom: none !important;
  }

  .details-card-box .developer-details .offers {
    border: none;
    padding: 10px 0 0 0 !important;
  }

  .development-plan-box .development-card .development-img-box {
    width: 160px !important;
  }

  .development-plan-box .development-card .development-img-box img {
    width: 100%;
    height: 100%;
  }

  .development-plan-box .development-card .development-card-details .heading {
    font-size: 15px !important;
  }

  .development-card .development-card-details .posted-date {
    font-size: 16px !important;
  }

  .inp_bar {
    padding: 3px 8px;
  }

  .bar_btn button {
    padding: 7px 9px;
  }

  .banner_section .content {
    width: 100%;
    padding: 3rem 1rem;
  }

  .banner_section .content h1,
  .video-background .content h1 {
    font-size: var(--banner-main-sm-heading);
  }

  .banner_section .content p,
  .video-background .content p {
    font-size: var(--banner-sm-text);
  }

  .banner_section .content p br,
  .video-background .content p br {
    display: none;
  }

  .select_banner input {
    font-size: var(--banner-sm-sub-text);
  }

  .banner_section .select_banner_btn,
  .video-background .select_banner_btn {
    padding: 0.3rem;
  }

  .banner_section .select_banner_btn button,
  .video-background .select_banner_btn button {
    font-size: var(--banner-sm-sub-text);
  }

  .unit_ul li button,
  .unit_ul li {
    font-size: var(--banner-sm-sub-text);
  }

  .jadoo {
    display: none;
  }

  .listing-property .ls-img {
    width: 70px;
  }

  .map h2 {
    font-size: 24px;
  }

  .map iframe {
    height: 320px;
  }

  .property_small {
    overflow: scroll
  }

  .property_small::-webkit-scrollbar {
    display: none;
  }

  .tab_banner .tb_btn {
    font-size: var(--banner-sm-sub-text);
    padding: 0.4rem 1.2rem !important;
  }

  .popular_properties h1,
  .neighborhood_properties h1 {
    font-size: var(--banner-main-sm-sub-heading) !important;
  }

  .btn_popular {
    width: 100%;
    justify-content: space-between;
    padding-right: 7px;
  }

  .btn_popular .inp_populer {
    background-color: white;
  }

  .video-background {
    padding: 0;
  }

  .video-background .content {
    margin-top: 0;
  }

  .property-item .p-4 .h5,
  .our_project .col-lg-4 h4 {
    font-size: var(--card-sm-title)
  }

  .property-item .p-4 p,
  .property-item small,
  .property-item .p-4 h5 span,
  .our_project .col-lg-4 p,
  .property-item .card_footer span {
    font-size: var(--card-sm-sub-title);
  }

  .property-item .p-4 h5,
  .our_project .col-lg-4 h6 {
    font-size: var(--card-sm-price);
  }

  .neighborhood_properties .total_properties {
    line-height: 25px !important;
  }

  .neighborhood_properties .col-lg-4 .main_card .content_neignor h5 {
    font-size: var(--card-sm-title);
  }

  .neighborhood_properties .col-lg-4 .main_card .content_neignor .link {
    font-size: var(--card-sm-sub-title);
    white-space: nowarp;
  }


  .neighborhood_properties .col-lg-4 .main_card .img {
    width: 138px;
    height: 138px !important;
  }

  .neighborhood_properties .col-lg-4 .main_card .img img {
    object-fit: cover;
  }

  .main_banner_home img {
    width: 100%;
    height: auto;
  }

  .main_banner_home .agent {
    height: 600px !important
  }

  .banner_section .tab_pan_project {
    width: 96%;
    margin: 0 auto;
  }

  .intant-value h2,
  .property-value p {
    font-size: var(--body-sm-main-heading);
  }

  .intant-value p,
  .property-card p {
    font-size: var(--body-sm-sub-heading);
  }

  .value-text h3 {
    font-size: var(--body-sm-title);
  }


  .value-text p {
    font-size: var(--body-sm-para);
  }

  .property-value .value-img {
    height: 320px;
    overflow: hidden;
  }

  .property-value .value-img img {
    object-fit: cover;
  }

  .listing_main h1,
  .listing_main .discrip_head {
    font-size: var(--body-sm-sub-heading)
  }

  .main_similer h2 {
    font-size: var(--body-sm-main-heading-2)
  }

  .listing_main h2 {
    font-size: var(--body-sm-price)
  }

  .listing_main h2 span,
  .listing_main small {
    font-size: var(--body-sm-small-tag)
  }

  .listing_main .discrip_subhead {
    font-size: var(--body-sm-para)
  }

  .card_modal,
  .call_modal {
    width: 86% !important;
  }

  .gallery-input-card .input-box {
    height: 240px !important;
  }

  .new_tab_banner .third-line {
    display: none !important;
  }

  .new_tab_banner .tab_section {
    width: 100% !important;
    margin: 0 !important;
  }

  .new_tab_banner .select_banner {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .new_tab_banner .select_banner select {
    padding: 0 28px 0 32px ;
    width: 100% !important;
  }

  .new_tab_banner .select_banner select option {
    font-size: 10px !important
  }

  .first-line {
    display: none
  }


  .new_content_box .select_banner select,
  .new_content_box .select_banner input {
    font-size: var(--banner-sm-input) !important;
  }

  .input_new_parent .home_icon {
    margin-right: 7px !important;
  }



}



@media screen and (max-width:576px) {
  .priWill-policy p,.policy ul li, .priv-policy h5, .priv-policy p {
   
    line-height: 25px ;
  }
  .policy h3 {
    font-size: 18px ;
    line-height: 25px;
  }
  .policy{
    padding: 0;
  }
  .faqs-heading h2,.privce-policeys h2,.priv-policy h2{
    font-size: 25px;
    line-height: 30px;
  }
  .faq-mian-box {
    padding: 5px;
  }
  .contactSUbmit{
  font-size: 14px;
  width: 200px !important;
  }
  .contact_us .inp_section h2{
    font-size: 25px;
    line-height: 30px;
  }
  .contact_us .inp_section p{
    font-size: 14px;
  }
  .agencyCity{
    padding: 0;
  }
  .agentFeature{
    gap: 0;
  }
  .agentSelect{
    padding: 0 10px !important;
  }
  .instantValueBox{
    padding: 0;
  }
  .instantValueBox .v-card{
    margin-right: 0;
  }
  .ourProList{
    flex-wrap: wrap;
  }
  .projectMap iframe{
    height: 300px;
  }
  .projectDetailImgs{

    height: 300px;
  
  }
  .projectBtnBox{
    width: 100%;
  }
  .projectBanner{
 
    padding: 1rem 0rem !important;
    }
  .user_setting .row ul li:first-child {
    margin-left: 9px;
  }
  .profileButoon{
   
    top: -106px;
  
  
  }
  .marquee-w {
    height: 57px;
  }
  .new_content_box h1{
    font-size: 16px;
  }

  .colored-box {
    height: 290px;
  }

  .item-box {
    flex-direction: column;
  }

  .entrance-container .main-img {
    height: 25px;
  }

  .enterNextImg {
    margin-left: -7px !important;
  }

  .top-project h2 {
    line-height: 30px;
  }

  .projectProfileImg img {
    height: 113px !important;
  }

  .projctmainCard {
    height: 350px !important;
  }

  .projctmainCard .project-heading h2 {
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .projectSlect {
    font-size: 12px;
  }

  .cardChooseSer {
    margin: 0 !important;
    margin-top: 2px !important;
  }

  .aboutTextbox {
    font-size: 18px;
  }

  .aboutTextboxpara {
    font-size: 14px !important;
  }

  .aboutWhat {
    font-size: 25px;
  }

  .cardMap iframe {
    height: 180px !important;
  }

  .cardCarusal .slick-track .img-tab {
    width: 90% !important;
  }

  .cardCarusal .slick-track .img-tab img {
    width: 100% !important;
  }

  .card_modalNew {
    height: max-content !important;
  }

  .cardNewImg {
    height: 180px !important;
  }

  .location .society-name {
    font-size: 20px;
  }

  .location .society-location {
    font-size: 12px;
  }

  .cardImges,
  .cardImges .img-box {
    width: 100% !important;
  }

  .gallery-input-card .name {
    font-size: 16px;


  }

  .gallery-input-card .owner {
    font-size: 12px;

  }

  .main_similer h2 {
    font-size: 16px !important;
  }

  .profileImg img {
    height: 75px;
  }

  .read-button .slot-read-function {
    font-size: 12px;
  }

  .listing_main h2 span {
    margin-left: 0;
  }

  .tab_pan h6 {
    font-size: 12px;
    margin: 8px;
  }

  .commonPopup {
    width: 98% !important;
    height: 350px;
    overflow: auto;
  }

  .SeoSecImg {
    height: 170px !important;
  }

  .footer .row .col-12 h5 {
    margin-bottom: 25px;
  }

  .footerULNew li {
    padding-bottom: 5px;
  }

  .footerUL {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footerUL li {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footerUL li span {

    text-align: center;
  }

  .footer .row .col-12 h6 {
    margin-bottom: 14px;
  }

  .footer .footer_inp {
    flex-direction: column;
  }

  .footer .footer_inp input {
    border-bottom: 1px solid lightgray;
    margin-bottom: 21px;
  }

  .footEmailErr {
    top: 35% !important;
  }

  .footer {
    padding: 15px !important;
    text-align: center;
  }

  .footer .social_link {
    flex-direction: column;
  }

  .footer .social_link span:first-child {
    padding: 10px 0;
  }

  .neughb {
    font-size: 25px;
    white-space: nowrap;
  }

  .popular_properties h1 {
    font-size: 25px !important;
  }

  .see_property_btn_div {


    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .select_banner_btn {
    margin: 8px 0 !important;
  }

  .unit_ul ul li {
    padding: 3px 5px;
  }

  .mor_option .more_div {
    width: 100%;
  }

  .homeBanner h1 {
    font-size: 25px !important;
    line-height: 32px !important;
    margin-top: 15px;
  }

  .homeBanner p {
    font-size: 12px !important;
    line-height: 22px !important;
  }

  .nav_ul {
    padding-left: 0;
    padding-right: 12px;
  }

  .top_bar {
    padding-bottom: 0 !important;
  }

  .side_menu ul li,
  .side_menu ul li button {
    font-size: 20px;
  }

  .menueNew {
    font-size: 16px !important;
  }

  .agency_staff .content_box .profile_section {
    gap: 5px;
    padding: 0;
    border-right: none;
  }

  .agency_staff .content_box .profile_section .profile_img {
    width: 68px;
    height: 68px;
  }


  .agency_staff .content_box .profile_section .user_data h6 {
    font-size: 11px !important;
  }

  .agency_staff .content_box .profile_section .user_data p,
  .agency_staff .content_box .main_profile .profile_quota p {
    font-size: 8px !important;
  }

  .add_propertey_main .locationPurpos h6 {
    font-size: 12px;
  }

  .agency_staff .content_box .main_profile .add_staff_btn,
  .agency_setting .d-flex button {
    font-size: 10px;
    font-weight: 500;
    line-height: 19px;
    padding: 0.2rem 0.6rem;
  }

  .agency_staff .content_box .profile_section .user_data p svg,
  .agency_staff .content_box .main_profile .profile_quota p svg {
    font-size: 8px !important;
  }

  .listing_table table thead th {
    font-size: 11px;
  }

  .listing_table table tbody td,
  .listing_table table tbody tr th {
    font-size: 10px;
  }

  .add_propertey_main .heading_mian {
    font-size: 20px;
    margin-bottom: 0;
  }

  .inp_login label {
    font-size: 11px !important;
  }


  .inp_login input {
    padding: .4rem .6rem;
    font-size: 10px;
    margin-top: 1px !important;
  }

  .inp_login .target_icon {
    width: 12px;
    height: 12px;
    margin-top: 0px;
  }

  .inp_login textarea {
    font-size: 10px;
    min-height: 80px;
    margin-top: 1px !important;
  }

  .image_section .row .col-12 .btn1 {
    font-size: 9px;
    width: 100px !important;
  }

  .parent_main_box {
    width: 15px !important;
    height: 15px !important;
    border-radius: 3px !important;
  }

  .parent_main_box .child_box {
    font-size: 8px !important;
    width: 12px !important;
    height: 12px !important;
  }

  .user_setting .row ul li {
    font-size: 13px;
  }

  .inp_login .contact_contry .img_container {
    margin-top: 1px;
    width: 100px;
  }



  .inp_login .contact_contry .img_container img {
    width: 30px;
  }


  .inp_login .contact_contry .img_container span {
    font-size: 11px;
  }

  .inp_login p,
  .inp_login select {
    font-size: 10px;
  }

  .buy_product .table_row {
    padding: 0;
  }

  .buy_product .table_row h5 {
    font-size: 14px;
  }

  .buy_product .table_body .data_pro .img {
    width: 30px;
    height: 30px;
  }

  .buy_product .table_body .data_pro .img img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .buy_product .table_body button {
    font-size: 10px;
    padding: 0.1rem 0.4rem;
  }

  .buy_product .table_body .data_pro span {
    font-size: 10px;
  }


  .buy_product .table_body .btn_carts button {
    font-size: 10px !important;
    padding: 0.1rem 0.4rem !important;
  }

  .buy_product .table_body .btn_carts .quantity_btn {
    padding: 0.1rem 0.7rem !important;
  }

  .buy_product .table_body .btn_carts button svg {
    width: 10px;
  }

  .buy_product .content_box .heading_mian {
    font-size: 18px;
  }

  .buy_product .content_box .heading_mian span {
    font-size: 11px;
  }

  .order_summery .heading {
    font-size: 16px !important;
  }

  .add_propertey_main .cart_item span {
    font-size: 10px !important;
  }

  .add_propertey_main .cart_item .total,
  .add_propertey_main .cart_item .total span {
    font-size: 12px !important;
  }

  .add_propertey_main .cart_item button {
    font-size: 10px;
    padding: 4px 10px;
  }

  .btn_popular button {
    font-size: 12px;
    padding: 0.2rem 0.8rem;
  }


  .new_inp_login_1 input {
    width: fit-content !important;
  }

  .new_inp_populer .new_input {
    font-size: 12px;
  }

  .new_inp_populer .new_svg {
    font-size: 17px !important;
  }

  .new_inp_populer {
    padding: 0.45rem 0.2rem;
  }

  .heading {
    font-size: 17px;
  }

  .dashboard_main .new_dashboard_listing_page {
    padding: 0 !important;
  }

  .dashboard_main .new_dashboard_listing_page .box .title {
    font-size: 11px !important;
    white-space: nowrap;
  }

  .dashboard_main .new_dashboard_listing_page .box .count {
    font-size: 12px !important;
  }

  .card_main_listing .dashboard_listing_page {
    justify-content: space-around;
    gap: 50px;
  }

  .credits_box h6 {
    font-size: 12px;
  }

  .credits_box div p {
    font-size: 10px;
  }

  .new-scroll-box .dashboard_listing_page .box .icons {
    width: 25px;
    height: 25px;
  }

  .new-scroll-box .dashboard_listing_page .box .icons img {
    width: 100%;
    height: 100%;
  }

  .satate_dashboard .dashboard_listing_page .box .title,
  .satate_dashboard .dashboard_listing_page .box .count {
    font-size: 10px;
    line-height: 15px;
  }

  .satate_dashboard .dashboard_listing_page .box h6 {
    font-size: 12px;
    margin: 5px 0 !important;
  }

  .satate_dashboard .chart {
    padding: 0 10px;
  }

  .satate_dashboard .chart h6 {
    font-size: 9px;
    line-height: 0;
    margin-bottom: 10px;
  }

  .satate_dashboard .chart p {
    font-size: 8px;
    line-height: 14px;
  }

  .listing_table table tbody td .plateform {
    font-size: 7px;
    padding: 0.4rem 0.6rem;
  }


  .add_propertey_main h3 {
    font-size: 17px;
  }

  .add_propertey_main .locationPurpos h4 {
    font-size: 14px !important;
    margin-bottom: 0;
  }

  .locationPurpos .location_svg {
    width: 12px;
  }

  .locationPurpos .location_svg_2 {
    width: 17px;
  }


  .locationPurpos .h6 {
    font-size: 11px;
    margin-bottom: 0;
  }

  .add_propertey_main .locationPurpos button {
    font-size: 9px;
  }

  .tab_lable p {
    font-size: 14px;
  }

  .propertyimage h5,
  .image_section .row .col-12 ul li,
  .propertyimage p {
    font-size: 10px;
  }

  .propertyimage .video_link .d-flex label {
    font-size: 12px;
  }

  .propertyimage .video_link input,
  .propertyimage .video_link input::placeholder {
    font-size: 10px;
  }

  .propertyimage .video_link img {
    width: 16px !important;
    margin-right: 3px;
  }

  .propertyimage .h3 {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .locationPurpos .pakistan_property-img {
    width: 70px !important;
    height: 25px !important;
    margin-right: 10px;
  }

  .add_propertey_main .content_box .submit_btn {
    font-size: 12px;
    padding: 0.3rem 0.8rem;
  }

  .heading_title_main button {
    font-size: 10px;
    padding: 4px 16px;
  }

  .qouta_main .inp_date input,
  .qouta_main .inp_date input::placeholder {
    font-size: 12px;
  }

  .new_search_bar input,
  .new_search_bar input::placeholder {
    font-size: 12px !important;
  }

  .banner_section .content h1 {
    font-size: 25px;
    line-height: 42px;
  }

  .tab_section .d-flex {
    flex-direction: column;
    line-height: 20px;
  }

  .select_banner {
    width: 100% !important
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.MuiDivider-vertical.MuiDivider-flexItem.css-1tk1bks-MuiDivider-root {
    display: none;
  }

  .unit_ul ul {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .banner_section {
    padding-bottom: 0;
  }

  .banner_section .content {
    height: 100%;
    margin-top: 0;
  }

  .main_banner_home {
    padding: 0 15px !important;
  }

  .btn_popular {
    padding-left: 7px;
  }

  .tab_pan_project .search_bar_main .inp_serch {
    width: -webkit-fill-available !important;
    display: flex;
    justify-content: space-between;
  }

  .inp_serch .range-box {
    width: 90px;
    justify-content: space-between;
  }

  .inp_serch .range-box input {
    width: 30% !important;
  }


  .tab_pan_project .search_bar_main .inp_serch label {
    font-size: var(--banner-xs-option);
  }


  .tab_pan_project .search_bar_main .inp_serch select option,
  .tab_pan_project .search_bar_main .inp_serch input,
  .tab_pan_project .search_bar_main .inp_serch span {
    font-size: var(--banner-xs-sub-option-1) !important;
  }

  .tab_pan_project .search_bar_main .btn_box button {
    font-size: var(--banner-xs-sub-option);
    padding: 0.1rem 0.4rem;
  }

  .search_bar_main .search_box {
    display: grid !important;
    gap: 14px 0;
  }

  .market span {
    font-size: var(--banner-sm-sub-text);
    margin-right: .3rem;
    white-space: nowrap;
  }

  .market .market_check {
    width: 10px !important;
    height: 10px !important;
  }

  .price_filter h6 {
    font-size: 14px;
  }

  .range_dropDown h5,
  .price_filter input {
    font-size: var(--banner-xs-sub-option) !important;
  }

  .top-project h2 {
    font-size: var(--body-xs-heading);
  }

  .project-heading h2 {
    font-size: var(--card-xs-heading);
  }

  .project-heading span {
    font-size: var(--card-xs-sub-heading);
  }

  .project-heading p {
    font-size: var(--card-xs-price);
  }

  .area_unit_para {
    font-size: 14px !important;
  }


  .intant-value h2,
  .property-value p {
    font-size: var(--body-xs-main-heading);
  }

  .intant-value p,
  .property-card p {
    font-size: var(--body-xs-sub-heading);
  }


  .value-text h3,
  .v-card h3 {
    font-size: var(--body-xs-title);
  }


  .value-text p,
  .intant-value h5 {
    font-size: var(--body-xs-para);
  }

  .v-card p {
    font-size: var(--body-xs-sub-para);
  }


  .intant-value button {
    font-size: var(--body-xs-btn);
  }

  .instant_main_modal {
    width: 94% !important;
    height: 94%;
    margin: 3% auto;
  }

  .property-item .position-relative .position-absolute {
    font-size: var(--card-xs-sub-title);
    padding: .4rem .5rem;
  }

  .property-item .p-4 h5 {
    margin-bottom: 0;
    /* font-size:var(--card-xs-price) */
  }

  .property-item .p-4 p {
    font-size: var(--card-xs-sub-title);
  }

  .property-item .p-4 .h5 {
    font-size: var(--card-xs-title);
    margin-bottom: 0;
  }

  .property_small small svg {
    font-size: var(--card-xs-svg) !important;
    margin-right: 0.2rem !important;
  }

  .property-item small {
    font-size: var(--card-xs-sub-title);
  }

  .new_listing_card_img {
    height: 200px !important;
  }

  .l-s h4,
  .listing-property .new_search_result_box {
    font-size: var(--body-xs-heading)
  }

  .map h2 {
    font-size: var(--body-xs-sub-heading);
    line-height: 30px;
    margin-top: 30px;
  }

  .listing-mian a,
  .listing-mian span {
    font-size: var(--body-xs-btn) !important
  }

  /* .property-item .p-4 h5 span{
    font-size:10px !important;
   } */


  .listing_main_img img {
    height: 300px;
  }


  .listing_main h1,
  .listing_main .discrip_head {
    font-size: var(--body-xs-sub-heading)
  }

  .listing_main h1 {
    line-height: 28px;
  }

  .main_similer h2 {
    font-size: var(--body-xs-main-heading-2);
    line-height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .listing_main h2 {
    font-size: var(--body-xs-price);
    margin-bottom: 0;
    line-height: 22px;
  }

  .listing_main h2 span,
  .listing_main small {
    font-size: var(--body-xs-small-tag);
  }

  .listing_main small {
    padding: 5px 0 !important;
  }

  .listing_main .discrip_subhead {
    font-size: var(--body-xs-para)
  }

  .listing_main .new_property_small {
    gap: 0 16px;
  }

  .listing_main .discription,
  .mian_feature .row .col-lg-3 span {
    font-size: var(--body-xs-sub-para);
  }


  .profile_map {
    height: 300px !important;
  }

  .form_radio label,
  .form_radio span {
    font-size: var(--body-xs-sub-para);
  }

  .logo_prop .Sign_inBtn,
  .login_modal .Sign_inBtn {
    font-size: var(--body-xs-btn);
    padding: 8px 10px !important;
  }

  .info_btns button,
  .content_info {
    font-size: var(--body-xs-btn) !important;
  }

  .heading_info {
    padding-top: 0;
  }

  .card_modal,
  .call_modal {
    width: 94% !important;
    max-height: calc(100vh - 200px);
    overflow: auto;
  }


  .card_modal .tab {
    font-size: var(--body-xs-btn);
    white-space: nowrap;
  }


  .gallery-input-card .input-box {
    height: 100% !important;
    overflow: unset !important;
  }

  .card_modal .new_modal_row {
    height: 450px !important;
    /* overflow: hidden; */
  }


  .contact-box .contact-col,
  .contact-box .contact-col-1 {
    padding: 10px 16px !important;
  }


  .new_tab_banner .select_banner {
    width: -webkit-fill-available !important;
  }

  .new_tab_banner .select_banner select {
    width: 100% !important;
  }

  .card-text .link {
    font-size: 13px !important;
  }

  .agents .card-img {
    width: 120px !important
  }

  .new_tab_banner .tab_section {
    width: 100% !important;
  }

  .imgbox-text .first-box .heading {
    font-size: var(--body-xs-heading)
  }

  .main-row .third-box {
    margin-top: 10px;
  }

  .new_fifth_row .agency {
    flex-direction: column;
    gap: 20px;
  }


}





@media screen and (max-width:500px) {




  /* .top_bar .enquiry {
    order: 2;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  } */

  /* .top_bar .nav_btn {
    order: 1;
  } */

  .agent .agen_content {
    width: 100%;
  }

  .select_banner input {
    display: block;

  }

  .select_banner select {
    display: block;
    width: 250px;
  }



  .mor_option button {
    width: 43%;
  }

  /* .btn_popular {
    margin-bottom: 1rem;
    width: 100%;
  } */

  /* .btn_popular button {
    width: 36%;
    margin: 0;
  } */

  /* .inp_populer {
    width: 100%;
  } */

  .inp_populer input {
    width: 100%;
  }

  

  .user_setting .row ul li:last-child {
    margin-right: 50px;
  }

  .location-box .house-price {
    font-size: 20px !important;
  }

  .details-card-box .slot .price {
    font-size: 15px !important;
    margin-top: 5px;
  }

  .housing-society .floor-heading,
  .development-upadtes .development-heading {
    font-size: 25px !important;
    text-align: center;
  }

  .development-upadtes .development-heading {
    line-height: 36px;
  }

  .new_location_map {
    width: 100% !important;
    height: 300px !important;
  }




}

@media screen and (max-width:400px) {
  .neighborCity {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 !important;
  }

  .neighborCity .content_neignor {
    flex-direction: row !important;
    margin-top: 10px;
    align-items: center;
    padding: 0 !important;
  }

  .neighborCity .content_neignor h1 {
    margin: 0;
    padding: 0 5px;
    font-size: 22px !important;
  }

  .neighborCity .content_neignor h5 {
    margin: 0;
    padding: 0 5px;
    font-size: 16px !important;
  }

  .neighborCity .content_neignor p {
    margin: 0;
    padding: 0 5px;
    font-size: 12px !important;
  }

  .projectSearch {
    display: none;
  }

  .projectSearchnew {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-right: 6px;
  }

  .projectSearchnew input {
    width: 100%;

  }

  .NavHaeder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 0;
  }

  .select_banner input {
    display: block;
    width: 220px;
  }

  .select_banner select {
    display: block;
    width: 220px;
  }




}